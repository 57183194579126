<template>
  <div>
    <figure class="icon-pull-quote fc-padding-y-8 fc-padding-x-0 fc-margin-y-9 fc-margin-x-0">
      <blockquote class="icon-pull-quote__blockquote">
        <div class="fc-flex-col-center fc-padding-x-9">
          <p class="icon-pull-quote__text fc-margin-y-1">
            "{{ body.text }}"
          </p>
          <figcaption class="icon-pull-quote__author">
            - {{ body.author }}
          </figcaption>
          <div class="icon-pull-quote__icon-circle fc-flex-col-center">
            <font-awesome-icon :icon="['fc', 'gloves']" class="icon-pull-quote__icon" />
          </div>
        </div>
      </blockquote>
    </figure>
  </div>
</template>

<script>
export default {
  name: 'IconPullQuote',
  props: {
    body: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
  .icon-pull-quote {
    border-top: solid 1px $color-dark-gray;
    border-bottom: solid 1px $color-dark-gray;
    color: $color-secondary-dark-gray;
    padding-top: get-spacing-level(5);

    &__blockquote {
      margin: 0;
    }

    &__text {
      font-size: get-font-size-level(8);
      text-align: center;
    }

    &__author {
      font-size: get-font-size-level(7);
      text-transform: uppercase;
      font-weight: bolder;
    }

    &__icon-circle {
      margin-top: get-spacing-level(8);
      border: solid 1px #d73c4b;
      border-radius: 50%;
      padding: 25px;
      width: 50px;
      height: 50px;
    }

    &__icon {
      color: $color-red;
      font-size: get-font-size-level(10);
      width: get-spacing-level(8);
    }
  }
</style>
