<template>
  <div class="featured-articles">
    <Carousel
      screen-label="featured articles carousel"
      :timer-limit="7000"
      mode="timer_on_load"
      infinite-slides-enabled>
      <Slide
        v-for="article in articles"
        :key="article.uuid"
        :screen-label="article.coverImg.alt">
        <div class="featured-articles__slide fc-flex fc-column">
          <NuxtLink
            :to="article.fullSlug"
            class="featured-articles__link fc-flex fc-column"
            v-track-click="`${$options.name}_Carousel`">
            <StoryblokImg
              class="featured-articles__image fc-flex"
              :image="article.coverImg"
              sizes="sm:100vw md:100vw lg:640px"
              :width="640"
              :height="265" />
            <h2 class="featured-articles__title fc-text-black fc-heading-1">
              {{ article.title }}
            </h2>
          </NuxtLink>
          <p class="featured-articles__description">
            {{ article.description }}
          </p>
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from '@fc/angie-ui';
import { isArticles } from '~/utils/validators';
import StoryblokImg from '~/components/StoryblokImg.vue';

export default {
  name: 'FeaturedArticles',
  components: {
    Carousel,
    Slide,
    StoryblokImg
  },
  props: {
    articles: {
      type: Array,
      required: true,
      validator(articles) {
        return isArticles(articles);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.featured-articles {
  &__slide {
    text-align: center;

    @media #{$tablet-up} {
      text-align: left;
    }
  }

  &__image {
    order: 2;

    @media #{$tablet-up} {
      order: 1;
    }
  }

  &__title {
    padding: get-spacing-level(2) get-spacing-level(4) get-spacing-level(8);
    order: 1;

    @media #{$tablet-up} {
      padding: 0;
      margin-top: get-spacing-level(4);
      margin-bottom: get-spacing-level(4);
      order: 2;
    }
  }

  &__description {
    margin: get-spacing-level(4) get-spacing-level(4) get-spacing-level(1);
    order: 3;

    @media #{$tablet-up} {
      margin: get-spacing-level(1) 0 0;
      font-family: $font-stack-bold;
      min-height: get-block-size-level(10);
    }
  }
}
</style>
