<template>
  <section class="related-articles">
    <h2 class="related-articles__title fc-title fc-text-dark-gray-2">
      More From FightCamp
    </h2>
    <div class="md:fc-col-span-4 sm:fc-col-span-5">
      <SixArticlesSection
        :articles="articles"
        title-size="big"
        :no-top-border="true" />
    </div>
  </section>
</template>

<script>
import { isArticles } from '~/utils/validators';
import SixArticlesSection from '~/components/home/SixArticlesSection.vue';

export default {
  name: 'RelatedArticles',
  components: {
    SixArticlesSection,
  },
  props: {
    articles: {
      type: Array,
      required: true,
      validator(articles) {
        return isArticles(articles);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .related-articles {
    padding: get-spacing-level(8) 0;
    width: 100%;
    border-top: 1px $color-gray-border solid;
    border-bottom: 1px $color-gray-border solid;

    @media #{$tablet-up} {
      padding: 3.25rem get-spacing-level(0) get-spacing-level(0);
    }

    &__title {
      text-align: center;

      @media #{$tablet-up} {
        margin: get-spacing-level(0);
        text-align: left;
      }
    }
  }
</style>
