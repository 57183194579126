






















import { defineComponent } from '@nuxtjs/composition-api';
import ThreeStars from '~/components/hub/ThreeStars.vue';
import AuthorSection from '~/components/article/AuthorSection.vue';
import { Author } from '~/models/Author';

export default defineComponent({
  name: 'ArticleContributors',
  components: {
    AuthorSection,
    ThreeStars,
  },
  props: {
    contributors: {
      type: Array,
      required: false,
      default() {
        return [];
      },
      validator: (contributors: Author[]) => contributors.every(c => c instanceof Author),
    }
  },
});
