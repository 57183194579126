
































import { defineComponent, PropType, ref } from '@nuxtjs/composition-api';

import SitemapItem, { IGeneratedSitemapItem } from '~/components/sitemap/SitemapItem.vue';
import SitemapCategory, { ISitemapCategory } from '~/components/sitemap/SitemapCategory.vue';
import TertiaryButton from '~/components/TertiaryButton.vue';
import { Category } from '~/models/Category';

export default defineComponent<{
  sitemap: IGeneratedSitemapItem[],
  categories: Category[],
}, {
  sitemapCategories: Record<string, ISitemapCategory>
}>({
  name: 'SitemapHub',
  components: {
    TertiaryButton,
    SitemapItem,
    SitemapCategory,
  },
  props: {
    categories: {
      type: Array as PropType<Category[]>,
      required: true,
    },
    sitemap: {
      type: Array,
      required: true,
    }
  },
  setup(props) {
    const toggled = ref(false);
    const sitemapCategories: Record<string, ISitemapCategory> = props.categories.reduce((acc, { slug, name, route }) => ({
      ...acc,
      [slug]: {
        name,
        route,
        articles: [],
      }
    }), {});

    props.sitemap.forEach((item) => {
      const category = sitemapCategories[item.categorySlug];
      if (category && category.articles) {
        category.articles.push(item);
      }
    });

    function onToggled(_toggled: boolean) {
      toggled.value = _toggled;
    }

    return {
      onToggled,
      toggled,
      sitemapCategories
    };
  },
});
