<template>
  <div class="four-articles-section__inner-grid">
    <ArticleCard
      v-for="article in firstArticles"
      :key="article.uuid"
      class="sm:fc-col-span-2 md:fc-col-span-1"
      :article="article"
      title-size="big" />
    <div class="four-articles-section__line sm:fc-hidden fc-col-span-2" />
    <ArticleCard
      v-for="article in secondArticles"
      :key="article.uuid"
      class="sm:fc-col-span-2 md:fc-col-span-1"
      :article="article"
      title-size="big" />
  </div>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';
import { isArticles } from '~/utils/validators';
import ArticleCard from '../article/ArticleCard.vue';

export default {
  name: 'FourArticlesSection',
  components: { ArticleCard },
  props: {
    articles: {
      type: Array,
      required: true,
      validator(articles) {
        return isArticles(articles);
      }
    },
  },
  setup(props) {
    return {
      firstArticles: computed(() => props.articles.slice(0, 2)),
      secondArticles: computed(() => props.articles.slice(2, 4)),
    };
  }
};
</script>

<style lang="scss" scoped>
  .four-articles-section {
    &__inner-grid {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      margin: get-spacing-level(8) get-spacing-level(4);
      grid-row-gap: get-spacing-level(8);
      grid-column: span 2;

      @media #{$tablet-up} {
        margin: 0;
        grid-column-gap: get-spacing-level(9);
        grid-row-gap: get-spacing-level(8);
        grid-column: span 3;
        height: max-content;
      }

      @media #{$desktop} {
        grid-column-gap: get-spacing-level(9);
        grid-column: span 2;
      }
    }

    &__line {
      @media #{$tablet-up} {
        border-top: 1px solid $color-gray-border;
        height: 1px;
      }
    }
  }
</style>
