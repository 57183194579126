<template>
  <div class="sitemap-link">
    <div class="sitemap-link__container fcb-container-sm fc-flex fc-padding-y-0 fc-padding-x-2 fc-justify-center">
      <NuxtLink class="sitemap-link__link fc-text-red fc-uppercase fc-bold fc-margin-y-4 fc-margin-x-0" to="/sitemap">
        Explore All Articles
      </NuxtLink>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SitemapLink',
};
</script>

<style lang="scss" scoped>
.sitemap-link {
  position: relative;
  margin-top: get-spacing-level(4);
  margin-bottom: get-spacing-level(7);

  @media #{$tablet-up} {
    padding: get-spacing-level(0) get-spacing-level(6);
  }

  &__container {
    margin: get-spacing-level(0) get-spacing-level(4);
    border-top: 1px $color-gray-border solid;
    border-bottom: 1px $color-gray-border solid;

    @media #{$tablet-up} {
      margin: get-spacing-level(0) auto;
      padding: get-spacing-level(0) get-spacing-level(4);
    }
  }

  &__link {
    font-size: get-font-size-level(7);
    line-height: 1.4;

    @media #{$tablet-up} {
      font-size: get-font-size-level(5);
      line-height: 1.4;
    }
  }
}
</style>
