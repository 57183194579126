<template>
  <div>
    <ArticleHeader
      :article="article"
      :author-has-hubpage="authorHasHubpage" />
    <StickySidebar>
      <ShareIcons
        :url="article.fullUrl"
        :title="article.metaInfo.title"
        display-direction="column"
        :has-gap="false"
        size="big" />
    </StickySidebar>
    <div class="article-page__body fcb-container-sm fc-flex-col-center">
      <ArticleContent :content="articleContent" />
      <div class="article-page__blog-email-capture fc-bg-red">
        <BlogEmailCapture
          :cta-text="emailCaptureButtonCta"
          :title="emailCaptureTitle"
          :description="emailCaptureDescription" />
      </div>
      <AuthorSection
        :author="article.author"
        :author-has-hubpage="authorHasHubpage" />
      <ArticleContributors :contributors="article.contributors" />
      <RelatedArticles
        v-if="relatedArticles.length"
        :articles="relatedArticles"
        :current-article="article" />
      <div class="article-page__nav-buttons fc-flex fc-justify-center">
        <span v-if="prevArticleSlug">
          <NuxtLink
            :to="prevArticleSlug"
            class="article-page__nav-button button-layout button-secondary">
            Previous Article
          </NuxtLink>
        </span>
        <span v-if="nextArticleSlug">
          <NuxtLink
            :to="nextArticleSlug"
            class="article-page__nav-button button-layout button-secondary">
            Next Article
          </NuxtLink>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import {
  onMounted,
  useContext,
  useRouter,
  ref,
  computed,
  useFetch,
} from '@nuxtjs/composition-api';
import { useAnalytics } from '@fc/app-common';
import ArticleContent from '~/components/article/ArticleContent.vue';
import { Article } from '~/models/Article';
import ArticleHeader from '~/components/article/ArticleHeader.vue';
import AuthorSection from '~/components/article/AuthorSection.vue';
import ArticleContributors from '~/components/article/ArticleContributors.vue';
import RelatedArticles from '~/components/article/RelatedArticles.vue';
import BlogEmailCapture from '~/components/home/BlogEmailCapture.vue';
import StickySidebar from '~/layouts/StickySidebar/StickySidebar.vue';
import { useFetchBlogPage } from '~/hooks/useFetchBlogPage';
import { renderBlockValue } from '~/renderers/renderBlockValue';
import ShareIcons from '~/components/ShareIcons';

export default {
  name: 'ArticlePage',
  props: {
    authorHasHubpage: {
      type: Boolean,
      required: true,
      default: false,
    },
    article: {
      type: Article,
      required: true,
    },
    prevArticleSlug: {
      type: String,
      default: undefined,
    },
    nextArticleSlug: {
      type: String,
      default: undefined,
    },
    relatedArticles: {
      type: Array,
      required: true,
      validator: (articles) => articles.every((cat) => cat instanceof Article),
    }
  },
  components: {
    StickySidebar,
    ArticleContent,
    ArticleHeader,
    AuthorSection,
    RelatedArticles,
    ShareIcons,
    BlogEmailCapture,
    ArticleContributors,
  },
  setup(props) {
    const { $storybridge, error } = useContext();
    const analytics = useAnalytics();
    const router = useRouter();
    const previewContent = ref(null);
    const { page, fetchPage } = useFetchBlogPage('article');
    const emailCaptureTitle = ref('');
    const emailCaptureDescription = ref('');
    const emailCaptureButtonCta = ref('');

    onMounted(() => {
      // TODO move to hook
      $storybridge(() => {
        // eslint-disable-next-line no-undef
        const storyblokInstance = new StoryblokBridge();
        storyblokInstance.on('input', (event) => {
          if (event.story.id === props.article.uuid) {
            previewContent.value = event.story.content;
          }
        });
        storyblokInstance.on(['published', 'change'], () => {
          router.go({
            path: router.currentRoute,
            force: true,
          });
        });
      });

      setTimeout(() => {
        if (process.client) {
          analytics.pageViewed({ name: document.title });
        }
      }, 500);
    });

    useFetch(async () => {
      try {
        await fetchPage();
        emailCaptureTitle.value = renderBlockValue(page, 'article.email.title');
        emailCaptureDescription.value = renderBlockValue(page, 'article.email.description');
        emailCaptureButtonCta.value = renderBlockValue(page, 'article.email.button');
      } catch (err) {
        error({ statusCode: err.response.status, message: err.response.statusText });
      }
    });

    return {
      articleContent: computed(() => previewContent.value || props.article.content),
      emailCaptureTitle,
      emailCaptureDescription,
      emailCaptureButtonCta,
    };
  },
};
</script>

<style lang="scss" scoped>
.article-page {
  &__body {
    padding: get-spacing-level(4) get-spacing-level(4) get-spacing-level(0);
    width: 100%;

    @media #{$tablet-up} {
      padding: get-spacing-level(0) get-spacing-level(2);
    }
  }

  &__nav-buttons {
    width: 100%;
    gap: get-spacing-level(4);
    padding: get-spacing-level(12) 0;
    flex-direction: column;

    @media #{$tablet-up} {
      flex-direction: row;
    }

    span {
      min-width: 40%;
    }
  }

  &__blog-email-capture {
    border-radius: $container-radius;
    padding: get-spacing-level(8);
    width: 100%;
    max-width: 690px;
    margin: get-spacing-level(2) get-spacing-level(0) get-spacing-level(8);

    @media #{$tablet-up} {
      padding: get-spacing-level(12) get-spacing-level(0);
      margin-bottom: get-spacing-level(12);
    }
  }
}
</style>
