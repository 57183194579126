<template>
  <div class="categories-tablet-up fc-flex sm:fc-hidden fc-justify-between">
    <div
      v-for="category in categories"
      :key="category.uuid"
      class="categories-tablet-up__wrapper fc-flex-col-center">
      <CategoryButton :category="category" />
    </div>
  </div>
</template>

<script>
import CategoryButton from './CategoryButton.vue';

export default {
  name: 'CategoriesTabletUp',
  components: {
    CategoryButton,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    }
  },
};
</script>

<style lang="scss" scoped>
  .categories-tablet-up {
    padding: get-spacing-level(9) get-spacing-level(0) get-spacing-level(12);
    border-top: 1px solid $color-gray-border;
    flex-wrap: wrap;
    height: 100%;
    justify-content: center;

    @supports (not(aspect-ratio: 1)) {
      &__wrapper {
        margin: get-spacing-level(4);
      }
    }

    @supports (aspect-ratio: 1) {
      gap: get-spacing-level(8);

      @media #{$tablet-up} {
        column-gap: get-spacing-level(10);
      }

      @media #{$desktop} {
        column-gap: get-spacing-level(8);
      }
    }

    @media #{$tablet-up} {
      padding: get-spacing-level(10) get-spacing-level(10);
    }

    @media #{$desktop} {
      padding: get-spacing-level(12) get-spacing-level(0);
      justify-content: space-between;
    }

    &__wrapper {
      box-shadow: 0 4px 8px 4px rgba(15, 30, 60, 0.1);
      border-radius: $input-radius;
    }
  }
</style>
