<template>
  <NuxtLink
    :to="category.route"
    v-track-click="`${$options.name}_${category.name}`">
    <div class="categories-btn fc-flex fc-column">
      <p class="categories-btn__title fc-heading-2 fc-text-white fc-bg-blue">
        {{ category.name }}
      </p>
      <div class="categories-btn__image-container fc-bg-blue">
        <StoryblokImg
          v-if="category.blockImage.src"
          class="storyblok-img"
          :lazy="true"
          :image="category.blockImage"
          sizes="xs:330px, md:330px, lg:330px"
          :width="165"
          :height="175" />
      </div>
      <div class="categories-btn__explore button-layout button-primary button-small">
        Explore
      </div>
    </div>
  </NuxtLink>
</template>

<script>
import StoryblokImg from '~/components/StoryblokImg.vue';

export default {
  name: 'CategoryButton',
  components: {
    StoryblokImg,
  },
  props: {
    category: {
      type: Object,
      required: true,
    }
  },
};
</script>

<style lang="scss" scoped>
  .storyblok-img {
    filter: saturate(0%) opacity(0.65);
  }

  .categories-btn {
    @media #{$tablet-up} {
      width: 165px;
    }

    &__image-container {
      line-height: 0;
    }

    &__title {
      font-size: get-font-size-level(10);
      opacity: 0.9;
      text-align: center;
      width: 100%;
      padding: get-spacing-level(2) get-spacing-level(1);
      border-top-right-radius: $input-radius;
      border-top-left-radius: $input-radius;

      @media #{$tablet-up} {
        font-size: get-font-size-level(8);
      }
    }

    &__explore {
      margin: get-spacing-level(4);
    }

    @media #{$tablet-up} {
      &:hover .storyblok-img {
        filter: none;
      }

      &:hover &__title {
        background-color: $color-blue-tint;
        opacity: 0.8;
      }
    }
  }
</style>
