<template>
  <div>
    <LazyHydrate when-idle>
      <div class="hub-page__top-container">
        <StoryblokImg
          :image="header.coverImage"
          sizes="xs:100vw, md:100vw, lg:1800px"
          :width="1200"
          :height="420" />
        <h1 class="hub-page__image-title fc-title fc-text-white">
          {{ header.name }}
        </h1>
      </div>
    </LazyHydrate>
    <LazyHydrate when-visible>
      <div
        v-if="header.topics"
        class="hub-page__content fcb-container-sm">
        <div
          class="hub-page__show-me-more-container md:fc-flex-row-center fc-hidden"
          :class="{'hub-page__button--margin-bottom': areTopicsGreaterThan4 }">
          <h3
            class="hub-page__show-me-more-title fc-text-blue fc-heading-2"
            :class="{'hub-page__show-me-more-title--margin-bottom': areTopicsGreaterThan4 }">
            Show me more
          </h3>
          <div class="hub-page__button-container fc-flex">
            <NuxtLink
              v-for="buttonData in topicList"
              :key="buttonData.uuid"
              class="hub-page__button button-layout button-secondary button-small"
              :class="[{'hub-page__button--margin-bottom': areTopicsGreaterThan4 }, {'hub-page__button--disabled button-disabled': buttonData.uuid === header.uuid}]"
              :to="buttonData.route">
              {{ buttonData.name }}
            </NuxtLink>
          </div>
        </div>
        <SixArticlesSection
          v-if="isSectionShown(firstArticles)"
          class="sm:fc-margin-x-4"
          :articles="firstArticles"
          title-size="big" />
        <div
          v-else
          class="border sm:fc-hidden" />
        <div
          v-if="areFeaturedArticles"
          class="line sm:fc-margin-x-4 md:fc-hidden" />
        <FeaturedCategoryArticles
          v-if="areFeaturedArticles"
          :articles="articles" />
        <div
          v-if="areFeaturedArticles"
          class="line sm:fc-margin-x-4 md:fc-hidden" />
        <SixArticlesSection
          v-if="!header.isTopic & isSectionShown(secondArticles)"
          class="sm:fc-margin-x-4"
          :articles="secondArticles"
          title-size="small" />
        <div
          v-else
          class="border sm:fc-hidden" />
        <div
          class="hub-page__promotion-container fc-grid-col-3 sm:fc-margin-x-4"
          :class="{'hub-page__promotion-container--no-top-border': header.isTopic}">
          <div class="hub-page__blog-email-capture fc-bg-red md:fc-col-span-2 sm:fc-col-span-3 fc-flex fc-align-center">
            <BlogEmailCapture
              :cta-text="emailCaptureData.emailCaptureButtonCta"
              :title="emailCaptureData.emailCaptureTitle"
              :description="emailCaptureData.emailCaptureDescription" />
          </div>
          <div
            v-if="categoryAd.image"
            class="md:fc-col-span-1 sm:fc-col-span-3 fc-flex">
            <a
              aria-label="FightCamp promotional image"
              :href="categoryAd.link"
              rel="noopener"
              target="_blank">
              <StoryblokImg
                class="md:fc-flex"
                sizes="xs:100vw, sm:100vw, md:100vw, lg:700px"
                :lazy="true"
                :image="categoryAd.image"
                format="png"
                shape="roundedCorners"
                :width="300"
                :height="272" />
            </a>
          </div>
        </div>
        <SixArticlesSection
          v-if="header.isTopic ? isSectionShown(secondArticles) : isSectionShown(thirdArticles)"
          class="hub-page__last-article-section sm:fc-margin-x-4"
          :articles="header.isTopic ? secondArticles : thirdArticles"
          title-size="small" />
        <transition-group
          name="fade"
          tag="div"
          data-testid="paginatedArticles"
          class="hub-page__last-article-section sm:fc-margin-x-4">
          <SixArticlesSection
            v-for="sixArticles in paginatedArticles"
            :key="sixArticles[0].uuid"
            :articles="sixArticles"
            :no-middle-line="isMidLineShown"
            title-size="small" />
        </transition-group>
        <MoreContentButton
          v-if="showFetchButton"
          class="hub-page__content-button"
          @renderNext="renderNextArticles" />
      </div>
    </LazyHydrate>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@nuxtjs/composition-api';
import { useAnalytics } from '@fc/app-common';
import LazyHydrate from 'vue-lazy-hydration';
import StoryblokImg from '~/components/StoryblokImg.vue';
import BlogEmailCapture from '~/components/home/BlogEmailCapture.vue';
import FeaturedCategoryArticles from '~/components/hub/FeaturedCategoryArticles.vue';
import SixArticlesSection from '~/components/home/SixArticlesSection.vue';
import { isArticles } from '~/utils/validators';
import { categoryTopicPageMeta } from '~/utils/categoryTopicPageMeta';
import MoreContentButton from '~/components/MoreContentButton';

export default {
  name: 'HubPage',
  components: {
    StoryblokImg,
    BlogEmailCapture,
    FeaturedCategoryArticles,
    SixArticlesSection,
    LazyHydrate,
    MoreContentButton,
  },
  props: {
    header: {
      type: Object,
      required: true,
    },
    showFetchButton: {
      type: Boolean,
    },
    paginatedArticles: {
      type: Array,
      required: true,
    },
    articles: {
      type: Array,
      required: true,
      validator(articles) {
        return isArticles(articles);
      },
    },
    emailCaptureData: {
      type: Object,
      required: true,
    },
    categoryAd: {
      type: Object,
      required: true,
    }
  },
  head(props) {
    return categoryTopicPageMeta(props._props.header.metaInfo);
  },
  methods: {
    renderNextArticles() {
      this.$emit('renderNext');
      this.$analytics.elementClicked('More_Content_Button');
    }
  },
  setup(props) {
    const analytics = useAnalytics();
    const topicList = ref([]);
    const selectedTopic = props.header.topics.find(topic => topic.uuid === props.header.uuid);
    const nextArticleIndex = ref(props.header.isTopic ? 12 : 18);

    if (selectedTopic) {
      topicList.value = props.header.topics.filter(topic => topic.uuid !== selectedTopic.uuid);
      topicList.value.unshift(selectedTopic);
    } else {
      topicList.value = props.header.topics;
    }

    function isSectionShown(section) {
      return section.length >= 3;
    }

    onMounted(() => {
      analytics.pageViewed({ name: props.header.name });
    });

    return {
      areTopicsGreaterThan4: computed(() => props.header.topics.length > 4),
      topicList,
      firstArticles: computed(() => props.header.allArticles.slice(0, 6)),
      secondArticles: computed(() => props.header.allArticles.slice(6, 12)),
      thirdArticles: computed(() => props.header.allArticles.slice(12, 18)),
      nextArticleIndex,
      isSectionShown,
      isMidLineShown: computed(() => props.header.allArticles.length < nextArticleIndex.value),
      areFeaturedArticles: computed(() => props.articles.length > 0),
    };
  }
};
</script>

<style lang="scss" scoped>
  .hub-page {
    &__top-container {
      position: relative;
      width: 100%;
      max-width: 1200px;
      margin: 0 auto;
    }

    &__image-title {
      position: absolute;
      width: 100%;
      font-size: get-font-size-level(12);
      top: 58%;
      padding-left: get-spacing-level(7);

      @media #{$tablet} {
        font-size: get-font-size-level(18);
        top: 66%;
      }

      @media #{$desktop} {
        top: 64%;
        padding-left: get-spacing-level(13);
        font-size: 6.75rem;
      }
    }

    &__content {
      @media #{$tablet-up} {
        margin: 0 auto;
      }
    }

    &__show-me-more-container {
      border-top: 1px solid $color-gray-border;
      margin-top: get-spacing-level(8);

      &:not(.hub-page__button--margin-bottom) {
        padding: get-spacing-level(3) 0;
      }

      padding-top: get-spacing-level(3);
    }

    &__show-me-more-title {
      margin: 0 get-spacing-level(3) get-spacing-level(3);

      &:not(.hub-page__show-me-more-title--margin-bottom) {
        margin: get-spacing-level(0) get-spacing-level(8) get-spacing-level(0) get-spacing-level(0);
      }
    }

    &__button-container {
      flex-wrap: wrap;
      max-width: 640px;
      justify-content: center;
    }

    &__button {
      &:not(.hub-page__button--margin-bottom) {
        margin: 0 get-spacing-level(3) 0 0;
      }

      margin: 0 get-spacing-level(3) get-spacing-level(3) 0;

      &--disabled {
        border-color: $color-dark-gray;

        &:hover {
          border-color: $color-dark-gray;
          background-color: $color-dark-gray;
        }
      }
    }

    &__promotion-container {
      display: grid;
      grid-row-gap: get-spacing-level(12);
      padding: get-spacing-level(12) get-spacing-level(0) get-spacing-level(6);

      @media #{$tablet-up} {
        grid-row-gap: get-spacing-level(0);
        grid-column-gap: get-spacing-level(8);
        padding: get-spacing-level(12) get-spacing-level(4);
        border-top: 1px solid $color-gray-border;
      }

      @media #{$desktop} {
        padding: get-spacing-level(12) get-spacing-level(0);
      }

      &--no-top-border {
        border-top: none;
      }
    }

    &__blog-email-capture {
      border-radius: $container-radius;
      padding: get-spacing-level(8);

      @media #{$tablet-up} {
        padding: get-spacing-level(2) get-spacing-level(0);
      }
    }

    &__last-article-section {
      border-bottom: none;
    }

    &__content-button {
      @media #{$tablet-up} {
        margin: get-spacing-level(0) auto get-spacing-level(12);
      }
    }
  }

  .border {
    border-top: 1px solid $color-gray-border;
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }

  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }

  .line {
    border-top: 1px solid $color-gray-border;
    margin-top: get-spacing-level(6);
  }
</style>
