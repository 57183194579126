<template>
  <a
    class="image-video-link fc-flex-col-center fc-margin-y-8"
    :href="body.link.url"
    :title="body.title"
    :aria-label="`${image.alt}`"
    rel="noopener"
    target="_blank">
    <StoryblokImg
      class="image-video-link__img"
      :image="image"
      sizes="sm:100vw, md:720px"
      :width="640"
      :height="360" />
    <span class="image-video-link__play-button fc-flex-col-center ">
      <font-awesome-icon :icon="['fa', 'play']" />
    </span>
  </a>
</template>

<script>
import { Image } from '~/models/Image';
import StoryblokImg from './StoryblokImg.vue';

export default {
  name: 'ImageVideoLink',
  components: { StoryblokImg },
  props: {
    body: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {
      image: new Image(props.body.image)
    };
  }
};
</script>

<style lang="scss" scoped>
  .image-video-link {
    position: relative;

    &__img {
      aspect-ratio: 16/9;
    }

    &__play-button {
      border-radius: 50%;
      background-color: $color-red;
      opacity: 0.7;
      color: $color-white;
      position: absolute;
      height: 50px;
      width: 50px;

      @media #{$tablet-up} {
        height: 60px;
        width: 60px;
      }
    }
  }
</style>
