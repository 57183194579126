

































































import { defineComponent } from '@nuxtjs/composition-api';

import { SocialIcons } from '@fc/angie-ui';
import { Article } from '~/models/Article';
import { Author } from '~/models/Author';
import SixArticlesSection from '~/components/home/SixArticlesSection.vue';
import StoryblokImg from '~/components/StoryblokImg.vue';
import BlogEmailCapture from '~/components/home/BlogEmailCapture.vue';
import MoreContentButton from '~/components/MoreContentButton.vue';

export interface AuthorPageProps {
  author: Author;
  showFetchButton: boolean;
  emailCaptureData: any;
  initialArticles: Article[]
  paginatedArticles: Article[]
}

export default defineComponent<AuthorPageProps>({
  name: 'AuthorPage',
  components: {
    SocialIcons,
    SixArticlesSection,
    StoryblokImg,
    BlogEmailCapture,
    MoreContentButton,
  },
  props: {
    author: {
      type: Author,
      required: true,
    },
    showFetchButton: {
      type: Boolean,
      required: true,
      default: false,
    },
    emailCaptureData: {
      type: Object,
      required: true,
    },
    initialArticles: {
      type: Array,
      required: true,
      validator: (articles: Article[]) => articles.every(art => art instanceof Article)
    },
    paginatedArticles: {
      type: Array,
      required: true,
    }
  },
  setup(_, context) {
    return {
      renderNextArticles() {
        context.emit('renderNext');
      }
    };
  },
});
