












import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'StickySidebar',
  props: {
    rotate: {
      type: Boolean,
      required: false,
      default: false,
    },
    classes: {
      type: String,
      required: false,
      default: 'fc-hidden lg:fc-block'
    }
  },
});
