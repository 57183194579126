
























import { computed, defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'ShareIcons',
  props: {
    url: { type: String, required: true },
    title: { type: String, required: true },
    displayDirection: { type: String, default: 'row' },
    hasGap: { type: Boolean, default: true },
    size: { type: String, default: 'small' }
  },
  setup(props) {
    const icons = computed(() => [
      {
        name: ['fab', 'facebook-f'],
        label: 'facebook',
        src: `https://www.facebook.com/sharer/sharer.php?u=${props.url}`,
        bgColor: 'fc-bg-red'
      },
      {
        name: ['fab', 'twitter'],
        label: 'twitter',
        src: `https://twitter.com/intent/tweet?url=${props.url}`,
        bgColor: 'fc-bg-red-tint',
      },
      {
        name: ['fab', 'pinterest'],
        label: 'pinterest',
        src: `https://pinterest.com/pin/create/button/?url=${props.url}&description=${props.title}`,
        bgColor: 'fc-bg-red-shade',
      },
      {
        name: ['fa', 'envelope'],
        label: 'email',
        src: `mailto:info@example.com?&subject=Check%20this%20out!%20-%20${props.title}&body=${props.url}`,
        bgColor: 'fc-bg-red'
      }
    ]);
    const direction = computed(() => (props.displayDirection === 'column' ? 'fc-column' : 'fc-row'));
    const gapSpacing = computed(() => (props.hasGap ? 'share-icons--gap' : ''));
    const squareSize = computed(() => (props.size === 'big' ? 'share-icons__square--big' : 'share-icons__square--small'));

    return {
      icons,
      direction,
      gapSpacing,
      squareSize
    };
  }
});
