










import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'MoreContentButton',
  setup(_, context) {
    return {
      next() {
        context.emit('renderNext');
      }
    };
  },
});
