<template>
  <div class="trending-articles fc-flex-col-center fc-bg-eggshell">
    <h2 class="trending-articles__title fc-heading-1 fc-text-dark-gray-2">
      {{ sectionTitle }}
    </h2>
    <div
      v-for="article in articles"
      :key="article.uuid"
      class="trending-articles__wrapper fc-margin-x-0">
      <ArticleCard
        :article="article"
        title-size="small"
        read-more-color="red-shade" />
    </div>
  </div>
</template>

<script>
import { isArticles } from '~/utils/validators';
import ArticleCard from '../article/ArticleCard.vue';

export default {
  name: 'TrendingArticles',
  components: { ArticleCard },
  props: {
    articles: {
      type: Array,
      required: true,
      validator(articles) {
        return isArticles(articles);
      }
    },
    sectionTitle: {
      type: String,
      default: 'Trending'
    }
  },
};
</script>

<style lang="scss" scoped>
.trending-articles {
  text-align: center;
  height: 100%;
  padding: get-spacing-level(8) get-spacing-level(4);

  @media #{$tablet-up} {
    border-radius: $container-radius;
    text-align: left;
    padding: get-spacing-level(0) get-spacing-level(4);
  }

  &__title {
    @media #{$tablet-up} {
      align-self: flex-start;
    }
  }

  &__wrapper {
    width: 100%;
    margin-top: get-spacing-level(4);
  }

  &__wrapper:first-of-type {
    border-bottom: 1px solid $color-gray-border;
    padding-bottom: get-spacing-level(8);
  }
}
</style>
