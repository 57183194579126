

















import {
  defineComponent,
  ref,
  useContext,
  useFetch,
  useRoute,
  reactive,
  Ref,
} from '@nuxtjs/composition-api';
import chunk from 'lodash/chunk';
import { Spinner } from '@fc/angie-ui';

import { Route } from 'vue-router';
import { WithSelector } from '~/components/wrappers/WithSelector';
import { useFetchAuthor } from '~/hooks/useFetchAuthor';
import { useFetchPaginatedArticles } from '~/hooks/useFetchPaginatedArticles';
import { useFetchBlogPage } from '~/hooks/useFetchBlogPage';
import { selectAuthor } from '~/selectors/selectAuthor';
import { renderBlockValue } from '~/renderers/renderBlockValue';
import AuthorPage from '~/components/author/AuthorPage.vue';
import routeJson from '~/nuxtPageRoutes.json';
import { Article } from '~/models/Article';
import { objectHasKey } from '~/utils/helpers';

export default defineComponent({
  name: 'author',
  components: {
    Spinner,
    WrappedAuthorPage: WithSelector(AuthorPage, selectAuthor, {
      propTypesOverride: {
        author: Object,
        paginatedArticles: Array,
      }
    }),
  },
  async validate({ params }: Route) {
    return objectHasKey(routeJson, `/author/${params.author}`);
  },
  setup() {
    const { error } = useContext();
    const route = useRoute();
    const initialArticles = ref([]) as Ref<Article[]>;
    const paginatedArticles = ref([]) as Ref<Article[][]>;
    const paginationOptions = ref({
      coverImg: true,
      page: 1,
      perPage: 6,
      authorUuid: '',
    });
    const emailCaptureData = reactive({
      emailCaptureTitle: '',
      emailCaptureDescription: '',
      emailCaptureButtonCta: '',
    });

    const {
      author,
      fetchAuthor
    } = useFetchAuthor();
    const { page, fetchPage } = useFetchBlogPage('topic-hub');
    const {
      getPaginatedArticles,
      canFetchMore,
      newArticles,
    } = useFetchPaginatedArticles();

    async function setPaginationRequirements() {
      await fetchAuthor(route.value.params.author);

      paginationOptions.value.authorUuid = author.value.uuid;
    }

    async function fetchMoreArticles() {
      if (!paginationOptions.value.authorUuid.length) {
        await setPaginationRequirements();
      }

      paginationOptions.value.page += 1;
      await getPaginatedArticles(paginationOptions.value);
      chunk((newArticles.value), 6).forEach((_chunk) => paginatedArticles.value.push(_chunk));
    }

    useFetch(async () => {
      try {
        await setPaginationRequirements();
        await getPaginatedArticles(paginationOptions.value);
        if (!canFetchMore.value) throw new Error('Not a valid author.');

        await fetchPage();

        initialArticles.value = newArticles.value;
        emailCaptureData.emailCaptureTitle = renderBlockValue(page, 'hub.topic.email.title');
        emailCaptureData.emailCaptureDescription = renderBlockValue(page, 'hub.topic.email.description');
        emailCaptureData.emailCaptureButtonCta = renderBlockValue(page, 'hub.topic.email.button');
      } catch (err: any) {
        error({ statusCode: err.status, message: err.message });
      }
    });

    return {
      author,
      initialArticles,
      paginatedArticles,
      canFetchMore,
      fetchMoreArticles,
      emailCaptureData,
    };
  }
});
