<template>
  <div class="article__container">
    <RichTextRenderer :document="content" :options="options" />
  </div>
</template>

<script>
import Vue from 'vue';
import VueRichTextRenderer, { RichTextRenderer } from '@marvr/storyblok-rich-text-vue-renderer';

import PullQuote from '~/components/PullQuote';
import ContentImage from '~/components/ContentImage';
import ImageVideoLink from '~/components/ImageVideoLink';
import EmbedVideoYoutube from '~/components/EmbedVideoYoutube';

Vue.use(VueRichTextRenderer);

export default {
  name: 'ArticleContent',
  components: {
    RichTextRenderer,
  },
  props: {
    content: {
      type: [Object, String],
      required: true,
    },
  },
  setup() {
    return {
      options: {
        resolvers: {
          components: {
            pull_quote: PullQuote,
            content_image: ContentImage,
            image_video_link: ImageVideoLink,
            video_embed: EmbedVideoYoutube
          },
        }
      }
    };
  }
};
</script>

<style scoped lang="scss">
  .article__container {
    max-width: 690px;
    width: 100%;

    h1,
    h2,
    h3,
    h4 {
      margin: get-spacing-level(8) 0;
    }

    h1 {
      font-size: get-font-size-level(12);
    }

    h2 {
      font-size: get-font-size-level(10);
    }

    h3 {
      font-family: $font-stack-bold;
      font-size: get-font-size-level(7);
    }

    h4 {
      font-family: $font-stack-bold;
      font-size: get-font-size-level(7);
    }

    a {
      color: $color-red;
      font-weight: bolder;
    }

    p:first-of-type {
      margin-top: 0;
    }
  }
</style>
