














































import { computed, defineComponent } from '@nuxtjs/composition-api';
import { SocialIcons } from '@fc/angie-ui';
import { Author } from '~/models/Author';
import StoryblokImg from '~/components/StoryblokImg.vue';

export default defineComponent({
  name: 'AuthorSection',
  components: {
    SocialIcons,
    StoryblokImg,
  },
  props: {
    authorHasHubpage: {
      type: Boolean,
      required: true,
      default: false,
    },
    author: { type: Author, required: true },
    authorLabel: {
      type: String,
      required: false,
      default: 'About the author',
    }
  },
  setup(props) {
    const dynamicNavigation = computed(() => (props.authorHasHubpage ? 'NuxtLink' : 'span'));
    const icons = computed(() => props.author.socialLinks.slice(0, 5));
    return {
      dynamicNavigation,
      icons,
    };
  }
});
