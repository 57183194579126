<template>
  <div class="six-articles-section__container" :class="{'six-articles-section__container--no-top-border': noTopBorder}">
    <ArticleCard
      v-for="article in firstArticles"
      :key="article.uuid"
      class="sm:fc-col-span-3 md:fc-col-span-1"
      :article="article"
      :title-size="titleSize"
      :title-style="titleStyle" />
    <div v-if="!noMiddleLine" class="six-articles-section__line fc-col-span-3 sm:fc-hidden" />
    <ArticleCard
      v-for="article in secondArticles"
      :key="article.uuid"
      class="sm:fc-col-span-3 md:fc-col-span-1"
      :article="article"
      :title-size="titleSize"
      :title-style="titleStyle" />
  </div>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';
import { isArticles } from '~/utils/validators';
import ArticleCard from '../article/ArticleCard.vue';

export default {
  name: 'SixArticlesSection',
  components: { ArticleCard },
  props: {
    articles: {
      type: Array,
      required: true,
      validator(articles) {
        return isArticles(articles);
      },
    },
    titleSize: {
      type: String,
      default: 'small',
    },
    titleStyle: {
      type: String,
      default: 'bold',
    },
    noTopBorder: {
      type: Boolean,
      default: false,
    }
  },
  setup(props) {
    return {
      noMiddleLine: computed(() => props.articles.length < 4),
      firstArticles: computed(() => props.articles.slice(0, 3)),
      secondArticles: computed(() => props.articles.slice(3, 6)),
      articleClass: computed(() => article => (article === 'line' ? 'fc-col-span-3 sm:fc-hidden' : 'sm:fc-col-span-3 md:fc-col-span-1')),
    };
  }
};
</script>

<style lang="scss" scoped>
  .six-articles-section {
    &__container {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      padding: get-spacing-level(8) get-spacing-level(0) get-spacing-level(0);
      grid-row-gap: get-spacing-level(8);

      &:last-of-type {
        padding-bottom: get-spacing-level(8);
      }

      @media #{$tablet-up} {
        margin-top: 0;
        padding: get-spacing-level(4);
        grid-column-gap: get-spacing-level(9);
        border-top: 1px solid $color-gray-border;

        &--no-top-border {
          border-top: none;
        }
      }

      @media #{$desktop} {
        padding: get-spacing-level(5) get-spacing-level(0);

        &:last-of-type {
          padding-bottom: get-spacing-level(12);
        }
      }
    }

    &__line {
      @media #{$tablet-up} {
        border-top: 1px solid $color-gray-border;
      }
    }
  }
</style>
