<template>
  <div>
    <ImgPullQuote v-if="hasBodyImage" :body="body" />
    <IconPullQuote v-else :body="body" />
  </div>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';

import IconPullQuote from './IconPullQuote.vue';
import ImgPullQuote from './ImgPullQuote.vue';

export default {
  name: 'PullQuote',
  components: { IconPullQuote, ImgPullQuote },
  props: {
    body: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const hasBodyImage = computed(() => !!props.body?.image?.id);

    return {
      hasBodyImage,
    };
  }
};
</script>
