<template>
  <span class="punch-game">
    <div class="punch-game__announcement fc-flex fc-justify-center">
      <transition name="bounce">
        <h2 v-show="punchCount >= 20" class="punch-game__aw-yeah">AAAAAWWWWWWWWW YEAH!</h2>
      </transition>
    </div>

    <div class="punch-game__stats-row fc-flex-row-center">
      <GoalDonut
        class="punch-game__goal-donut"
        :value="punchCount"
        :total="20"
        :content="donutContent" />
    </div>

    <div class="punch-game__board fc-flex fc-justify-center fc-margin-x-0 fc-margin-y-8">
      <div
        ref="bag"
        class="bag"
        :style="{ backgroundImage: 'url(' + require('@/assets/BagMaster.png') + ')' }" />

      <div
        ref="musubi"
        class="musubi stance"
        :style="{ backgroundImage: 'url(' + require('@/assets/MusubiMaster.png') + ')' }" />
    </div>

    <div class="punch-game__rules fc-flex-row-center fc-padding-y-0 fc-padding-x-8">
      <p>Hit the "1", "2" and "k" keys or the buttons below</p>
    </div>

    <div class="punch-game__controls fc-flex fc-justify-center">
      <button class="button-layout button-normal punch-game__button" @click="jab">Jab</button>
      <button class="button-layout button-outline button-normal punch-game__button--secondary" @click="cross()">Cross</button>
      <button class="button-layout button-outline button-normal punch-game__button--secondary" @click="kick()">Kick</button>
    </div>
  </span>
</template>

<script>
import {
  ref, computed, onMounted, onUnmounted,
} from '@nuxtjs/composition-api';
import { darkgray } from '@fc/angie-ui/dist/styles/_js_exports.scss';
import GoalDonut from './GoalDonut.vue';

export default {
  name: 'PunchGame',
  components: {
    GoalDonut,
  },
  setup() {
    const punchCount = ref(0);
    const fire = ref(true);
    const musubi = ref(null);
    const bag = ref(null);

    function jab() {
      if (fire.value === true) {
        fire.value = false;
        musubi.value.classList.add('punch');
        punchCount.value += 1;
        bagMove();
        setTimeout(() => {
          musubi.value.classList.remove('punch');
          fire.value = true;
        }, 250);
      }
    }

    function bagMove() {
      bag.value.classList.add('bagMove');
      setTimeout(() => {
        bag.value.classList.remove('bagMove');
      }, 250);
    }

    onMounted(() => {
      window.addEventListener('keydown', keymonitor);
    });

    function cross() {
      if (fire.value === true) {
        fire.value = false;
        musubi.value.classList.add('cross');
        punchCount.value += 1;
        bagMove();
        setTimeout(() => {
          musubi.value.classList.remove('cross');
          fire.value = true;
        }, 300);
      }
    }

    function kick() {
      if (fire.value === true) {
        fire.value = false;
        musubi.value.classList.add('kick');
        punchCount.value += 1;
        bagMove();
        setTimeout(() => {
          musubi.value.classList.remove('kick');
          fire.value = true;
        }, 300);
      }
    }

    onUnmounted(() => {
      window.removeEventListener('keydown', keymonitor);
    });

    function keymonitor(event) {
      if (event.key === '1') {
        jab();
      }

      if (event.key === '2') {
        cross();
      }

      if (event.key === 'k') {
        kick();
      }
    }

    return {
      punchCount,
      fire,
      donutContent: computed(() => ({
        color: '#50e3c2',
        fontcolor: '#000000',
        bgcolor: darkgray,
        title: 'Goal',
        subtitle: 'Left',
        formatter: (value, total) => total - value,
      })),
      jab,
      cross,
      kick,
      musubi,
      bag,
    };
  },
};
</script>

<style scoped lang="scss">
.punch-game {
  width: 99%;

  @media #{$tablet} {
    width: 90%;
  }

  @media #{$desktop} {
    width: 60%;
  }

  &__controls {
    flex-wrap: wrap;
    margin: get-spacing-level(4) 0 get-spacing-level(8) 0;
  }

  &__button {
    width: get-spacing-level(16);
    margin: 0 get-spacing-level(4) get-spacing-level(4) get-spacing-level(4);

    &--secondary {
      width: get-spacing-level(16);
      background-color: $color-white;
      color: $color-black;
      margin: 0 get-spacing-level(4) get-spacing-level(4) get-spacing-level(4);
    }
  }

  &__announcement {
    height: 40px;
  }

  &__aw-yeah {
    text-align: center;
    margin: get-spacing-level(4) 0 0;
  }

  &__board {
    background-color: $color-light-gray;
    border-bottom: $color-blue get-spacing-level(4) solid;
  }
}

/* The following animation styles must be outside the scope of punch-game */
@mixin anim($animName, $steps, $animNbr, $animParams) {
  .#{$animName} {
    @content;

    animation: $animName steps($steps) $animParams;
  }

  @keyframes #{$animName} {
    from {
      background-position: 0 (-$spriteHeight * ($animNbr - 1));
    }

    to {
      background-position: -($spriteWidth * $steps) (-$spriteHeight * ($animNbr - 1));
    }
  }
}

/* element who's going to receive this class will be mirrored */
.flip {
  transform: scaleX(-1);
}

/* Musubi sprite tile dimensions */
$spriteWidth: 200px;
$spriteHeight: 200px;

/* Bag sprite tile dimensions */
$bagSpriteWidth: 200px;
$bagSpriteHeight: 200px;

/* musubi */
.musubi {
  width: $spriteWidth;
  height: $spriteHeight;
  position: relative;
  right: 2.5rem;
}

/* bag */
.bag {
  width: $bagSpriteWidth;
  height: $bagSpriteHeight;
  position: relative;
  right: get-spacing-level(8);
}

/* stance */
@include anim(
  $animName: stance,
  $steps: 4,
  $animNbr: 1,
  $animParams: 0.8s infinite
);

/* punch */
@include anim(
  $animName: punch,
  $steps: 4,
  $animNbr: 2,
  $animParams: 0.25s infinite
);

/* cross */
@include anim(
  $animName: cross,
  $steps: 4,
  $animNbr: 3,
  $animParams: 0.3s infinite
);

/* cross */
@include anim(
  $animName: kick,
  $steps: 4,
  $animNbr: 4,
  $animParams: 0.3s infinite
);

/* bagMove */
@include anim(
  $animName: bagMove,
  $steps: 4,
  $animNbr: 1,
  $animParams: 0.25s infinite
);

.bounce-enter-active {
  animation: bounce-in 0.5s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}
</style>
