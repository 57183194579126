<template>
  <div class="three-stars fc-flex fc-align-center fc-justify-between">
    <font-awesome-icon
      v-for="index in 3"
      :key="index"
      class="three-stars__star"
      :icon="['fc', 'star']"
      size="sm" />
  </div>
</template>

<script>
export default {
  name: 'ThreeStars',
};
</script>

<style lang="scss" scoped>
  .three-stars {
    width: 18%;

    @media #{$tablet-up} {
      width: 12%;
    }

    &__star {
      color: $color-blog-secondary-gray;

      @media #{$tablet-up} {
        font-size: get-spacing-level(6);
      }
    }
  }
</style>
