<template>
  <div>
    <Spinner
      :active="$fetchState.pending"
      :with-overlay="false" />
    <div
      v-if="!$fetchState.pending"
      class="home fcb-container-sm">
      <div class="home__top fc-flex fc-column md:fc-row">
        <div class="home__featured fc-flex fc-column fc-justify-between md:fc-col-span-2 sm:fc-col-span-3">
          <FeaturedArticles
            :articles="featuredArticles"
            class="fc-col-span-2" />
          <div class="home__icons fc-flex fc-column fc-align-center md:fc-row fc-col-span-12">
            <p class="home__icons-title fc-heading-1 fc-text-blue">
              Connect With Us
            </p>
            <SocialIcons
              class="home__social-icons"
              bg-color="red"
              :icons="iconList" />
          </div>
        </div>
        <LazyHydrate when-visible>
          <div class="home__trending md:fc-col-span-1 sm:fc-col-span-3">
            <TrendingArticles
              :articles="trendingArticles"
              :section-title="trendingSectionTitle" />
          </div>
        </LazyHydrate>
      </div>
      <div>
        <div class="home__middle">
          <LazyHydrate when-visible>
            <FourArticlesSection :articles="allArticles" />
          </LazyHydrate>
          <LazyHydrate when-idle>
            <WrappedCategoriesMobile
              :categories="categories"
              class="md:fc-hidden" />
          </LazyHydrate>
          <LazyHydrate when-visible>
            <div class="home__middle-right">
              <div class="home__blog-email-capture fc-flex fc-align-center md:fc-col-span-1 sm:fc-col-span-3 fc-bg-red">
                <BlogEmailCapture
                  :cta-text="emailCaptureButtonCta"
                  :is-tablet-up-column-order="true"
                  :title="emailCaptureTitle"
                  :description="emailCaptureDescription" />
              </div>
              <div
                v-if="homeAdOne"
                class="home__ad-one md:fc-col-span-1 sm:fc-col-span-3 md:fc-flex">
                <a
                  class="md:fc-flex"
                  aria-label="FightCamp promotional image"
                  :href="homeAdOne.link"
                  rel="noopener"
                  target="_blank"
                  @click="trackAdClick('homeAdOne', homeAdOne.link)">
                  <StoryblokImg
                    class="md:fc-flex"
                    sizes="xs:100vw, sm:100vw, md:100vw,lg:300px"
                    :lazy="true"
                    :image="homeAdOne.image"
                    shape="roundedCorners"
                    :width="300"
                    :height="272" />
                </a>
              </div>
            </div>
          </LazyHydrate>
        </div>
        <LazyHydrate when-visible>
          <WrappedCategoriesTabletUp
            v-if="categories.length > 0"
            :categories="categories" />
        </LazyHydrate>

        <LazyHydrate when-visible>
          <SixArticlesSection
            class="home__six-articles-container"
            :articles="allArticles" />
        </LazyHydrate>

        <LazyHydrate when-visible>
          <div
            v-if="homeAdTwo || homeAdThree"
            class="home__ad-container fc-flex"
            :class="{'home__ad-container--large': !homeAdTwo}">
            <a
              v-if="homeAdThree"
              aria-label="FightCamp promotional image"
              class="fc-col-span-2 md:fc-flex sm:fc-hidden"
              :class="{'home__large-ad--center': !homeAdTwo}"
              :href="homeAdThree.link"
              rel="noopener"
              target="_blank"
              @click="trackAdClick('homeAdThree', homeAdThree.link)">
              <StoryblokImg
                sizes="xs:100vw, sm:100vw, md:100vw, lg:600px"
                :lazy="true"
                :image="homeAdThree.image"
                shape="roundedCorners"
                :width="640"
                :height="272" />
            </a>
            <a
              v-if="homeAdTwo"
              aria-label="FightCamp promotional image"
              class="fc-col-span-1"
              :class="{'home__small-ad--center': !homeAdThree}"
              :href="homeAdTwo.link"
              rel="noopener"
              target="_blank"
              @click="trackAdClick('homeAdTwo', homeAdTwo.link)">
              <StoryblokImg
                sizes="xs:100vw, sm: 100vw, md:100vw, lg:300px"
                :lazy="true"
                :image="homeAdTwo.image"
                shape="roundedCorners"
                :width="300"
                :height="272" />
            </a>
          </div>
        </LazyHydrate>

        <LazyHydrate when-visible>
          <ThreeArticlesSection :articles="allArticles" />
        </LazyHydrate>
      </div>
    </div>
  </div>
</template>

<script>
import { SocialIcons, Spinner } from '@fc/angie-ui';
import LazyHydrate from 'vue-lazy-hydration';
import {
  useFetch, ref, defineComponent, useContext, useMeta, onMounted
} from '@nuxtjs/composition-api';
import { useAnalytics } from '@fc/app-common';
import { useFetchBlogPage } from '~/hooks/useFetchBlogPage';
import { useFetchArticles } from '~/hooks/useFetchArticles';
import TrendingArticles from '~/components/home/TrendingArticles.vue';
import FeaturedArticles from '~/components/home/FeaturedArticles.vue';
import BlogEmailCapture from '~/components/home/BlogEmailCapture.vue';
import FourArticlesSection from '~/components/home/FourArticlesSection.vue';
import SixArticlesSection from '~/components/home/SixArticlesSection.vue';
import ThreeArticlesSection from '~/components/home/ThreeArticlesSection.vue';
import StoryblokImg from '~/components/StoryblokImg.vue';
import CategoriesTabletUp from '~/components/home/CategoriesTabletUp.vue';
import CategoriesMobile from '~/components/home/CategoriesMobile.vue';
import { renderBlockValue } from '~/renderers/renderBlockValue';
import { WithHomePageArticles } from '~/components/wrappers/WithHomePageArticles';
import { WithSelector } from '~/components/wrappers/WithSelector';
import { selectCategories } from '~/selectors/selectCategories';
import { createSocialCardsTags } from '~/utils/socialCardsTags';
import { SITE_TITLE, SITE_DESCRIPTION, SITE_THUMBNAIL } from '~/utils/constants';
import { withStoryblokHeaderImgResize } from '~/utils/helpers';

export default defineComponent({
  name: 'Home',
  components: {
    FeaturedArticles: WithHomePageArticles(FeaturedArticles, {
      includeTypes: ['featured'],
      sliceParams: [0, 3],
    }),
    TrendingArticles: WithHomePageArticles(TrendingArticles, {
      includeTypes: ['trending'],
      sliceParams: [0, 2],
    }),
    SocialIcons,
    Spinner,
    LazyHydrate,
    BlogEmailCapture,
    ThreeArticlesSection: WithHomePageArticles(ThreeArticlesSection, {
      excludeTypes: ['trending', 'featured'],
      sliceParams: [10, 13],
    }),
    FourArticlesSection: WithHomePageArticles(FourArticlesSection, {
      excludeTypes: ['trending', 'featured'],
      sliceParams: [0, 4],
    }),
    SixArticlesSection: WithHomePageArticles(SixArticlesSection, {
      excludeTypes: ['trending', 'featured'],
      sliceParams: [4, 10],
    }),
    StoryblokImg,
    WrappedCategoriesTabletUp: WithSelector(CategoriesTabletUp, selectCategories),
    WrappedCategoriesMobile: WithSelector(CategoriesMobile, selectCategories),
  },
  head: {},
  setup() {
    const { error } = useContext();
    const featuredArticles = useFetchArticles();
    const trendingArticles = useFetchArticles();
    const allArticles = useFetchArticles();
    const { fetchPage, page } = useFetchBlogPage('home');
    const emailCaptureTitle = ref('');
    const emailCaptureDescription = ref('');
    const emailCaptureButtonCta = ref('');
    const homeAdOne = ref({
      image: null,
      link: ''
    });
    const homeAdTwo = ref({
      image: null,
      link: ''
    });
    const homeAdThree = ref({
      image: null,
      link: ''
    });
    const trendingSectionTitle = ref();
    const categories = ref([]);

    useFetch(async () => {
      try {
        await Promise.all([
          featuredArticles.fetchArticles({ tags: 'home-featured', coverImg: true, limit: 25 }),
          trendingArticles.fetchArticles({ tags: 'home-trending', coverImg: true, limit: 25 }),
          allArticles.fetchArticles({ coverImg: true, limit: 50 }),
          fetchPage(),
        ]);

        homeAdOne.value = renderBlockValue(page, 'home.ad.small_1');
        homeAdTwo.value = renderBlockValue(page, 'home.ad.small_2');
        homeAdThree.value = renderBlockValue(page, 'home.ad.large');
        emailCaptureTitle.value = renderBlockValue(page, 'home.email.title');
        emailCaptureDescription.value = renderBlockValue(page, 'home.email.description');
        emailCaptureButtonCta.value = renderBlockValue(page, 'home.email.button');
        trendingSectionTitle.value = renderBlockValue(page, 'home.trending.title');
        categories.value = renderBlockValue(page, 'home.categories_topics_list');
      } catch (err) {
        error({ statusCode: err.response.status, message: err.response.statusText });
      }
    });

    useMeta(() => ({
      title: SITE_TITLE,
      meta: [
        ...createSocialCardsTags({
          title: SITE_TITLE,
          description: SITE_DESCRIPTION,
          imageSrc: withStoryblokHeaderImgResize(SITE_THUMBNAIL),
          pageUrl: process.env.NUXT_ENV_BASE_URL
        })
      ]
    }));

    const analytics = useAnalytics();

    onMounted(() => {
      analytics.pageViewed({ name: SITE_TITLE });
    });

    function trackAdClick(name, url) {
      analytics.adClicked({ name, url });
    }

    return {
      trackAdClick,
      homeAdOne,
      homeAdTwo,
      homeAdThree,
      emailCaptureTitle,
      emailCaptureDescription,
      emailCaptureButtonCta,
      categories,
      iconList: [
        { name: 'twitter', url: 'https://twitter.com/joinfightcamp/' },
        { name: 'facebook', url: 'https://www.facebook.com/fightcamp/' },
        { name: 'instagram', url: 'https://www.instagram.com/fightcamp' },
        { name: 'youtube', url: 'https://www.youtube.com/channel/UCJ6J1r5u8CFsbyVZ03A6IaQ' },
        { name: 'pinterest', url: 'https://www.pinterest.com/JoinFightCamp' },
        { name: 'tiktok', url: 'https://www.tiktok.com/@fightcamp' },
      ],
      featuredArticles: featuredArticles.articles,
      trendingArticles: trendingArticles.articles,
      allArticles: allArticles.articles,
      trendingSectionTitle,
    };
  },
});
</script>

<style lang="scss" scoped>
.home {
  padding: get-spacing-level(6) get-spacing-level(0) get-spacing-level(0) get-spacing-level(0);

  @media #{$tablet-up} {
    padding: get-spacing-level(12) get-spacing-level(0) get-spacing-level(0) get-spacing-level(0);
  }

  &__top {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-row-gap: get-spacing-level(0);

    @media #{$tablet} {
      margin: 0 get-spacing-level(4);
      grid-column-gap: get-spacing-level(7);
    }

    @media #{$desktop} {
      margin: 0;
      grid-column-gap: get-spacing-level(9);
      grid-row-gap: 7.5rem;
    }
  }

  &__featured {
    @media #{$desktop} {
      flex: 0 1 640px;
    }
  }

  &__icons {
    padding: get-spacing-level(8) get-spacing-level(0);
    border-top: 1px solid $color-gray-border;

    @media #{$tablet-up} {
      padding: get-spacing-level(4) get-spacing-level(0);
      border-bottom: 1px solid $color-gray-border;
      gap: get-spacing-level(8);
    }
  }

  &__icons-title {
    line-height: 2.75rem;

    @media #{$tablet-up} {
      margin: 0;
      line-height: 1;
    }
  }

  &__social-icons {
    padding: get-spacing-level(2);
    gap: get-spacing-level(2);
    display: grid;
    grid-auto-flow: column;

    @media #{$tablet-up} {
      padding: get-spacing-level(0);
    }
  }

  &__middle {
    @media #{$tablet-up} {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-row-gap: get-spacing-level(14);
      margin: get-spacing-level(12) get-spacing-level(4);
      grid-column-gap: get-spacing-level(7);
    }

    @media #{$desktop} {
      grid-column-gap: 2.5rem;
      margin: get-spacing-level(12) 0;
    }
  }

  &__middle-right {
    display: grid;
    grid-row-gap: get-spacing-level(8);
    padding: get-spacing-level(0) get-spacing-level(4);

    @media #{$tablet} {
      grid-row-gap: get-spacing-level(12);
      grid-column: span 3;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: get-spacing-level(7);
      padding: get-spacing-level(0);
    }

    @media #{$desktop} {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      grid-row-gap: get-spacing-level(8);
      padding: get-spacing-level(0);
    }
  }

  &__blog-email-capture {
    border-radius: $container-radius;
    padding: get-spacing-level(7);

    @media #{$desktop} {
      padding: get-spacing-level(8) get-spacing-level(7);
    }
  }

  &__six-articles-container {
    margin: get-spacing-level(0) get-spacing-level(4);

    @media #{$tablet-up} {
      margin: get-spacing-level(0);
      padding: get-spacing-level(12) 0;
    }
  }

  &__inner-grid {
    grid-row: span 2;
  }

  &__articles_container {
    @media #{$tablet-up} {
      border-top: 1px solid $color-gray-border;
      border-bottom: 1px solid $color-gray-border;
    }
  }

  &__ad-container {
    display: grid;
    margin: get-spacing-level(12) get-spacing-level(4) 0;

    @media #{$tablet} {
      margin: 0 get-spacing-level(4);
    }

    &:not(.home__ad-container--large) {
      @media #{$tablet-up} {
        grid-template-columns: repeat(3, minmax(0, 1fr));
        grid-column-gap: get-spacing-level(9);
      }
    }

    @media #{$tablet-up} {
      padding: get-spacing-level(12) 0;
      border-top: 1px solid $color-gray-border;
      border-bottom: 1px solid $color-gray-border;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      grid-column-gap: get-spacing-level(9);
    }

    @media #{$desktop} {
      margin: 0;
    }
  }

  &__large-ad--center {
    grid-column-start: 2;
    grid-column-end: 5;
  }

  &__small-ad--center {
    grid-column-start: 2;
  }
}
</style>
