<template>
  <header class="blog-nav__header">
    <nav
      role="navigation"
      aria-label="main-navigation-menu"
      class="blog-nav__nav fc-flex fc-justify-between fc-bg-blue fc-text-white">
      <ul class="blog-nav__list">
        <li>
          <NuxtLink class="blog-nav__fc-logo" to="/">
            <span class="fc-screen-reader-only">Home</span>
            <font-awesome-icon :icon="['fc', 'logo']" class="blog-nav__fc-logo-icon" />
          </NuxtLink>
        </li>
        <li class="fc-flex fc-margin-y-0">
          <div
            v-for="cat in categories"
            :key="cat.name"
            class="blog-nav__heading-container sm:fc-hidden md:fc-hidden lg:fc-block">
            <DesktopDropDown
              :heading="cat.name"
              :category-route="cat.route"
              :topics="cat.topics" />
          </div>
        </li>
        <li class="blog-nav__button">
          <a
            href="https://joinfightcamp.com/?utm_source=organicBlog&utm_medium=explore&utm_content=homepage"
            target="_blank"
            rel="noopener"
            class="button-layout button-primary button-small"
            v-track-click="'Explore_FightCamp_Button'">
            Explore FightCamp
          </a>
        </li>
        <li class="blog-nav__mobile-icon" @click="handleMobileNavClose">
          <font-awesome-icon
            size="lg"
            :icon="isMobileOpen ? ['fc', 'plus'] : ['fas', 'bars']"
            class="blog-nav__bars"
            :class="{'blog-nav__close': isMobileOpen}" />
        </li>
      </ul>
    </nav>
    <div v-show="isMobileOpen" class="mobile">
      <div
        v-for="(cat, index) in categories"
        :key="cat.name">
        <MobileDropDown
          ref="mobileDropDown"
          :heading="cat.name"
          :topics="cat.topics"
          :category-object="cat"
          :is-last-category="isLastIndex(index)"
          @closeDrawer="isMobileOpen = !isMobileOpen" />
      </div>
      <div
        v-show="isMobileOpen"
        class="mobile-nav-overlay"
        @click="handleMobileNavClose" />
    </div>
  </header>
</template>

<script>
import {
  ref, watch, onMounted, nextTick, onBeforeUnmount
} from '@nuxtjs/composition-api';
import { isCategory } from '~/utils/validators';
import MobileDropDown from './MobileDropDown.vue';
import DesktopDropDown from './DesktopDropDown.vue';

export default {
  name: 'Nav',
  components: { MobileDropDown, DesktopDropDown },
  props: {
    categories: {
      type: Array,
      required: true,
      validator(categories) {
        return isCategory(categories);
      }
    }
  },
  setup(props) {
    const isMobileOpen = ref(false);
    const windowWidth = ref(0);
    const mobileDropDown = ref(null);
    function isLastIndex(index) {
      return index === props.categories.length - 1;
    }
    function onResize() {
      windowWidth.value = window.innerWidth;
    }
    function handleMobileNavClose() {
      mobileDropDown.value.forEach(element => {
        if (element.isActive) {
          element.toggle();
        }
        isMobileOpen.value = !isMobileOpen.value;
      });
    }
    onMounted(() => {
      nextTick(() => {
        window.addEventListener('resize', onResize);
      });
    });
    onBeforeUnmount(() => {
      window.removeEventListener('resize', onResize);
    });
    watch(windowWidth, (newVal) => {
      if (newVal > 1200) {
        isMobileOpen.value = false;
      }
    });
    return {
      isMobileOpen,
      handleMobileNavClose,
      isLastIndex,
      mobileDropDown,
    };
  },
};
</script>

<style lang="scss" scoped>
  .blog-nav {
    &__header {
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 2;
    }

    &__nav {
      position: relative;
      position: -webkit-sticky;
      top: 0;
      width: 100%;
      padding: 0 get-spacing-level(5);
      z-index: 2;

      @media #{$desktop} {
        position: relative;
      }
    }

    li {
      padding: 0;
      margin: 0;
    }

    &__list {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;
      height: 58px;
      width: 100%;
    }

    &__fc-logo {
      color: $color-white;
      padding: get-spacing-level(3) get-spacing-level(3) get-spacing-level(3) 0;
    }

    &__fc-logo-icon {
      width: 40px;
      height: 100%;
      align-self: center;
    }

    &__button {
      margin-left: auto;
      align-self: center;
    }

    &__bars {
      align-self: center;

      &:hover {
        cursor: pointer;
      }

      @media #{$desktop} {
        display: none;
      }
    }

    &__mobile-icon {
      align-self: center;
      margin-left: get-spacing-level(5);
      width: 21px;

      @media #{$desktop} {
        margin-left: 0;
        width: 0;
      }
    }

    &__heading-container {
      align-self: center;
      height: 100%;
    }

    &__close {
      transform: rotate(45deg);

      &:hover {
        cursor: pointer;
      }
    }
  }

  .mobile {
    position: absolute;
    z-index: 1;
    width: 100%;
    background: $spiralyze-light-gray;
    opacity: 0.96;

    @media #{$desktop} {
      display: none;
    }
  }

  .mobile-nav-overlay {
    position: fixed;
    right: 0;
    top: 0;
    z-index: -2;
    height: 100vh;
    width: 100vw;
  }
</style>
