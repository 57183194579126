<template>
  <div class="page-404">
    <h1 class="page-404-title">
      Sorry, the page you were looking for doesn't exist.
    </h1>
    <p class="page-404-subtitle">
      Take that frustration out on the boxing bag. Punch your way back to the
      <NuxtLink to="/">
        home
      </NuxtLink>
      page!
    </p>
    <PunchGame class="page-404-game" />
  </div>
</template>

<script>
import PunchGame from '~/components/404/PunchGame.vue';

// TODO Fix fetching of categories in default layout
// known issue - https://github.com/nuxt/nuxt.js/issues/3776

export default {
  name: 'error',
  props: {
    error: { type: Object, default: () => ({}) }
  },
  components: {
    PunchGame,
  },
  layout: 'error-nav'
};
</script>

<style scoped lang="scss">
  .page-404 {
    display: flex;
    flex-direction: column;
    margin: get-spacing-level(4) 0;
    align-items: center;

    &-title {
      font-family: $font-stack-bold;
      font-size: get-font-size-level(8);
      margin: get-spacing-level(4);
    }

    &-subtitle {
      margin: get-spacing-level(4) get-spacing-level(4) get-spacing-level(8) get-spacing-level(4);
    }

    &-game {
      border: $color-light-gray 1px solid;
      border-radius: $container-radius;
    }
  }
</style>
