<template>
  <div class="blog__container fc-flex fc-column">
    <WrappedPageAnnouncement v-if="!$fetchState.pending" :announcement="layout.announcement" />
    <WrappedNav v-if="!$fetchState.pending" :categories="rawCategoriesData" />
    <main class="blog__content fc-flex fc-column">
      <Nuxt />
    </main>
    <LazyHydrate when-visible>
      <footer v-if="!$fetchState.pending" class="blog__footer">
        <WrappedFooter :categories="rawCategoriesData" />
        <SitemapLink />
        <BottomFooter />
      </footer>
    </LazyHydrate>
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import { useFetch, useContext } from '@nuxtjs/composition-api';
import { useFetchCategories } from '~/hooks/useFetchCategories';
import { WithSelector } from '~/components/wrappers/WithSelector';
import { selectCategories } from '~/selectors/selectCategories';
import sitemap from '~/sitemapRoutes.json';
import { useFetchLayout } from '~/hooks/useFetchLayout';
import BottomFooter from './Footer/BottomFooter.vue';
import Footer from './Footer/Footer.vue';
import SitemapLink from './Footer/SitemapLink.vue';
import Nav from './Nav/Nav.vue';
import { selectAnnouncement } from '../selectors/selectAnnouncement';
import PageAnnouncement from './PageAnnouncement/PageAnnouncement.vue';

export default {
  name: 'Layout',
  components: {
    WrappedFooter: WithSelector(Footer, selectCategories),
    BottomFooter,
    LazyHydrate,
    WrappedNav: WithSelector(Nav, selectCategories),
    WrappedPageAnnouncement: WithSelector(PageAnnouncement, selectAnnouncement),
    SitemapLink,
  },
  setup() {
    const { error } = useContext();
    const { rawCategoriesData, fetchCategories } = useFetchCategories();
    const { layout, fetchLayout } = useFetchLayout();

    useFetch(async () => {
      try {
        await Promise.all([
          fetchLayout(),
          fetchCategories(),
        ]);
      } catch (err) {
        error({ statusCode: err.response.status, message: err.response.statusText });
      }
    });

    return {
      rawCategoriesData,
      sitemap,
      layout
    };
  }
};
</script>

<style scoped lang="scss">
.blog {
  &__container {
    min-height: 100vh;
  }

  &__content {
    overflow-x: hidden;
    flex: 1 0 auto;
  }

  &__footer {
    flex-shrink: 0;
  }
}
</style>
