



















import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { Announcement } from '@fc/angie-ui';
import { PageAnnouncementModel } from '~/models/PageAnnouncement';

export default defineComponent({
  name: 'PageAnnouncement',
  components: {
    Announcement,
  },
  props: {
    announcement: {
      type: Object as PropType<PageAnnouncementModel>,
      required: true,
    }
  },
});
