<template>
  <section v-if="canShowList" class="featured-category-articles">
    <span class="featured-category-articles__heading-container fc-flex fc-justify-center">
      <ThreeStars />
      <h2 class="featured-category-articles__heading fc-heading-1 fc-text-blue">Featured</h2>
      <ThreeStars />
    </span>
    <div class="featured-category-articles__articles-container fc-grid-col-3 fc-bg-eggshell sm:fc-padding-x-4 md:fc-padding-x-8 fc-padding-y-8">
      <div v-if="isTabletUp" class="featured-category-articles__article--main lg:fc-grid-col-3 featured-category-articles__article">
        <StoryblokImg
          class="article-card__image fc-col-span-2"
          :image="mainArticle.coverImg"
          :lazy="true"
          sizes="xs:100vw, md:800px"
          :width="600"
          :height="250" />
        <span class="fc-justify-center fc-flex fc-column fc-padding-x-4">
          <p class="featured-category-articles__article--main-title fc-text-black fc-bold">
            {{ mainArticle.title }}
          </p>
          <NuxtLink
            class="featured-category-articles__main-article-link"
            :to="mainArticle.fullSlug">
            <span class="button-layout button-primary button-small fc-hidden md:fc-block">Read More</span>
            <span class="fc-uppercase fc-bold md:fc-hidden">Read More +</span>
          </NuxtLink>
        </span>
      </div>
      <ArticleCard
        v-else
        :key="mainArticle.uuid"
        class="fc-col-span-3"
        :article="mainArticle"
        read-more-color="red-shade"
        title-size="normal" />
      <div v-if="canShowRemainingList" class="featured-category-articles__line fc-col-span-3 fc-hidden md:fc-block" />
      <ArticleCard
        v-for="article in articleList"
        :key="article.uuid"
        :article="article"
        title-size="normal"
        read-more-color="red-shade"
        class="featured-category-articles__article" />
    </div>
  </section>
</template>

<script>
import {
  computed, ref, onMounted, onBeforeUnmount
} from '@nuxtjs/composition-api';
import { isArticles } from '~/utils/validators';
import ArticleCard from '~/components/article/ArticleCard.vue';
import StoryblokImg from '~/components/StoryblokImg.vue';
import ThreeStars from './ThreeStars.vue';

export default {
  name: 'FeaturedCategoryArticles',
  components: {
    ArticleCard,
    StoryblokImg,
    ThreeStars,
  },
  props: {
    articles: {
      type: Array,
      default: () => [],
      required: true,
      validator(articles) {
        return isArticles(articles);
      }
    }
  },
  setup(props) {
    const isTabletUp = ref(false);

    const mediaWatchHandler = () => {
      isTabletUp.value = mediaQuery?.matches;
    };

    let mediaQuery;

    onMounted(() => {
      isTabletUp.value = global.innerWidth > 768;
      mediaQuery = global.matchMedia('(min-width: 768px)');
      mediaQuery.addEventListener('change', mediaWatchHandler);
    });

    onBeforeUnmount(() => {
      mediaQuery?.removeEventListener('change', mediaWatchHandler);
    });

    return {
      mainArticle: computed(() => props.articles[0]),
      articleList: computed(() => props.articles.slice(1, 4)),
      canShowList: computed(() => props.articles.length > 0),
      canShowRemainingList: computed(() => props.articles.slice(1, 4).length === 3),
      isTabletUp,
    };
  }
};
</script>

<style lang="scss" scoped>
  .featured-category-articles {
    margin: get-spacing-level(6) get-spacing-level(0);

    @media #{$tablet-up} {
      margin: get-spacing-level(0);
      padding: get-spacing-level(12) get-spacing-level(0);
      border-bottom: none;
      border-top: 1px solid $color-gray-border;
    }

    a {
      text-decoration: none;
    }

    &__heading-container {
      margin-bottom: get-spacing-level(3);

      @media #{$tablet-up} {
        margin-bottom: get-spacing-level(8);
      }
    }

    &__heading {
      margin: 0 get-spacing-level(4);

      @media #{$tablet-up} {
        font-size: get-font-size-level(18);
        margin: 0 get-spacing-level(8);
      }
    }

    &__articles-container {
      border-radius: $container-radius;
      display: grid;
      grid-row-gap: get-spacing-level(4);

      @media #{$tablet-up} {
        grid-row-gap: get-spacing-level(9);
        grid-column-gap: get-spacing-level(7);
      }
    }

    &__article {
      grid-column: span 3;
      padding-top: get-spacing-level(4);
      border-top: 1px solid $color-gray-border;

      @media #{$tablet-up} {
        grid-column: span 1;
        padding-top: get-spacing-level(0);
        border: none;
      }

      &:first-of-type {
        grid-column: span 3;
        padding-top: get-spacing-level(0);
        border: none;
      }

      &--main {
        @media #{$tablet-up} {
          display: grid;
          grid-template-columns: repeat(3, minmax(0, 1fr));
          grid-column-gap: get-spacing-level(9);
        }
      }
    }

    &__main-article-link {
      margin: 0 auto 0 0;
    }

    &__article--main-title {
      font-size: get-font-size-level(7);
      margin: get-spacing-level(4) 0 get-spacing-level(2) 0;

      @media #{$tablet-up} {
        font-size: get-font-size-level(9);
        margin-bottom: get-spacing-level(5);
      }
    }

    &__line {
      border-top: 1px solid $color-gray-border;
    }
  }
</style>
