










import { defineComponent, PropType } from '@nuxtjs/composition-api';

export interface IGeneratedSitemapItem {
  url: string,
  title: string,
  categorySlug: string,
}

export default defineComponent({
  name: 'SitemapItem',
  props: {
    item: {
      type: Object as PropType<IGeneratedSitemapItem>,
      required: true,
    }
  },
});
