










































import {
  ref, defineComponent, PropType, Ref
} from '@nuxtjs/composition-api';

type ValidCollapsibleBoxAlignment = 'center' | 'between';

interface IExpandedElement {
  scrollHeight: number;
  style: {
    maxHeight: string | null;
    transition: string | null;
  }
}

export default defineComponent<{
  heading: string,
  headingLabel: string,
  hasBottomBorder: boolean,
  alignment: ValidCollapsibleBoxAlignment,
  titleClass: string,
}>({
  name: 'CollapsibleBox',
  props: {
    heading: {
      type: String,
      required: true,
    },
    headingLabel: {
      type: String,
      required: true,
    },
    hasBottomBorder: {
      type: Boolean,
      default: false,
    },
    alignment: {
      type: String as PropType<ValidCollapsibleBoxAlignment>,
      required: false,
      default: 'between',
    },
    iconSize: {
      type: String,
      required: false,
      default: 'lg',
    },
    titleClass: {
      type: String,
      default: 'fc-text-left',
    }
  },
  setup(_, context) {
    const isActive = ref(false);
    const expandedEl = ref({
      scrollHeight: 0,
      style: {
        maxHeight: null,
        transition: null,
      }
    }) as Ref<IExpandedElement>;
    function toggle() {
      isActive.value = !isActive.value;

      if (expandedEl.value.style.maxHeight) {
        expandedEl.value.style.maxHeight = null;
      } else {
        expandedEl.value.style.maxHeight = `${expandedEl.value.scrollHeight * 2}px`;
        expandedEl.value.style.transition = 'max-height 0.6s ease';
      }

      context.emit('onToggled', isActive.value);
    }

    return {
      isActive,
      toggle,
      expandedEl,
    };
  },
});
