<template>
  <div class="desktop-drop-down">
    <NuxtLink
      class="desktop-drop-down__category-link fc-heading-3 fc-flex fc-text-white fc-padding-x-8"
      :to="categoryRoute">
      {{ heading }}
    </NuxtLink>
    <ul class="desktop-drop-down__topics">
      <li
        v-for="topic in topics"
        :key="topic.uuid"
        class="desktop-drop-down__topic-title fc-bg-eggshell fc-flex">
        <NuxtLink :to="topic.route" class="desktop-drop-down__topic-title--link fc-text-blue">
          {{ topic.name }}
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'DesktopDropDown',
  props: {
    heading: {
      type: String,
      required: true,
    },
    categoryRoute: {
      type: String,
      required: true,
    },
    topics: {
      type: Array,
      required: true,
    }
  },
};
</script>

<style lang="scss" scoped>
  .desktop-drop-down {
    height: 100%;

    &:hover {
      cursor: pointer;
      background-color: $color-egg-shell;
      color: $color-blue;
    }

    &__category-link {
      height: 100%;
      font-size: get-font-size-level(6);
      align-items: center;
    }

    &:hover &__category-link {
      color: $color-blue;
    }

    &__topics {
      display: none;
      position: absolute;
      z-index: 1;
      margin: 0;
      padding: 0;
      flex-direction: column;
      width: 286px;
    }

    &:hover &__topics {
      display: flex;
    }

    &__topic-title {
      font-size: get-font-size-level(6);
      margin: 0;
      justify-content: flex-start;
      opacity: 0.96;
      list-style-type: none;

      &:hover {
        background: $color-blog-gray;
        cursor: pointer;
        opacity: 1;

        a {
          color: $color-white;
        }
      }

      &:last-of-type {
        border: 1px solid $color-egg-shell;
        border-bottom-left-radius: $container-radius;
        border-bottom-right-radius: $container-radius;

        &:hover {
          border: 1px solid $color-blog-gray;
        }
      }
    }

    &__topic-title--link {
      flex: 1;
      padding: get-spacing-level(3) get-spacing-level(0) get-spacing-level(3) get-spacing-level(8);
    }
  }
</style>
