

























import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import StoryblokImg from '~/components/StoryblokImg.vue';
import { Image } from '~/models/Image';
import {
  CopyrightDetailsBlok,
  ImgBlok,
  UrlBlok,
} from '~/blokTypes';

interface Body {
  image: ImgBlok;
  copyright_details: CopyrightDetailsBlok[];
  url: UrlBlok;
  title: string;
  component: string;
}

export default defineComponent({
  components: { StoryblokImg },
  name: 'ContentImage',
  props: {
    body: {
      type: Object as PropType<Body>,
      required: true
    },
  },
  setup(props) {
    const {
      url,
      image,
      copyright_details: copyrightDetails,
    } = props.body;
    const { story } = url ?? {};
    const [copyrightDetail] = copyrightDetails ?? [];

    const externalImageUrl = url?.url ?? '';
    const hasExternalImageUrl = externalImageUrl.length;
    const internalImageUrl = story?.url ?? '';
    const hasInternalImageUrl = internalImageUrl.length;
    const hasImageUrl = hasExternalImageUrl || hasInternalImageUrl;

    let imageContainer = 'div';

    const imageContainerOptions = {} as any;
    if (hasImageUrl) {
      if (hasInternalImageUrl) {
        imageContainer = 'NuxtLink';
        imageContainerOptions.to = `/${internalImageUrl.replace('{{story.slug}}', story?.slug ?? '')}`;
      } else {
        imageContainer = 'a';
        imageContainerOptions.href = externalImageUrl;
        imageContainerOptions.target = '_blank';
        imageContainerOptions.referrerpolicy = 'no-referrer';
      }
    }

    return {
      imageContainer,
      imageContainerOptions,
      image: computed(() => new Image(image)),
      copyrightDetails: computed(() => ({
        title: copyrightDetail?.title,
        src: copyrightDetail?.link?.url,
      }))
    };
  }
});
