<template>
  <figure class="fc-margin-x-0 fc-margin-y-8">
    <blockquote class="fc-flex fc-column fc-container">
      <StoryblokImg
        class="fc-flex img-pull-quote__img"
        :lazy="true"
        :image="image"
        :width="690"
        :height="350"
        sizes="sm:100vw, lg:690px" />
      <div class="img-pull-quote__text-container fc-flex-col-center fc-padding-y-8 fc-padding-x-8">
        <p class="img-pull-quote__text fc-margin-y-1">
          "{{ body.text }}"
        </p>
        <figcaption class="img-pull-quote__author">
          - {{ body.author }}
        </figcaption>
      </div>
    </blockquote>
  </figure>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';
import { Image } from '~/models/Image';
import StoryblokImg from './StoryblokImg.vue';

export default {
  name: 'ImgPullQuote',
  components: { StoryblokImg },
  props: {
    body: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {
      image: computed(() => new Image(props.body.image))
    };
  }
};
</script>

<style lang="scss" scoped>
  .img-pull-quote {
    &__img {
      aspect-ratio: 69/35;
      position: relative;
      top: 4px;
    }

    &__text-container {
      background-color: $color-blue;
      color: $color-white;
      padding-top: get-spacing-level(5);
    }

    &__text {
      font-size: get-font-size-level(8);
      text-align: center;
    }

    &__author {
      font-size: get-font-size-level(7);
      text-transform: uppercase;
      font-weight: bolder;
    }
  }
</style>
