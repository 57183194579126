<template>
  <div>
    <Spinner
      :active="$fetchState.pending"
      :with-overlay="false" />
    <WrappedSitemapHub
      v-if="!$fetchState.pending"
      :sitemap="sitemap"
      :categories="rawCategoriesData" />
  </div>
</template>

<script>
import { useFetch, useContext } from '@nuxtjs/composition-api';
import { Spinner } from '@fc/angie-ui';
import { useFetchCategories } from '~/hooks/useFetchCategories';
import SitemapHub from '~/components/SitemapHub.vue';
import { WithSelector } from '~/components/wrappers/WithSelector';
import { selectArticlesSitemap } from '~/selectors/selectArticlesSitemap';
import sitemap from '~/sitemapRoutes.json';

export default {
  name: 'Sitemap',
  components: {
    WrappedSitemapHub: WithSelector(SitemapHub, selectArticlesSitemap),
    Spinner,
  },
  setup() {
    const { error } = useContext();
    const { rawCategoriesData, fetchCategories } = useFetchCategories();

    useFetch(async () => {
      try {
        await fetchCategories();
      } catch (err) {
        error({ statusCode: err.response.status, message: err.response.statusText });
      }
    });

    return {
      rawCategoriesData,
      sitemap,
    };
  }
};
</script>
