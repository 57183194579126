<template>
  <div class="three-articles-section__container">
    <div
      v-for="article in articles"
      :key="article.uuid"
      class="md:fc-col-span-1 sm:fc-col-span-3">
      <ArticleCard
        :article="article"
        title-size="small" />
    </div>
  </div>
</template>

<script>
import { isArticles } from '~/utils/validators';
import ArticleCard from '../article/ArticleCard.vue';

export default {
  name: 'ThreeArticlesSection',
  components: { ArticleCard },
  props: {
    articles: {
      type: Array,
      required: true,
      validator(articles) {
        return isArticles(articles);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .three-articles-section {
    &__container {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-row-gap: get-spacing-level(8);
      padding: get-spacing-level(12) get-spacing-level(4);

      @media #{$tablet-up} {
        padding: get-spacing-level(12) get-spacing-level(4);
        grid-row-gap: get-spacing-level(9);
        grid-column-gap: get-spacing-level(7);
      }

      @media #{$desktop} {
        grid-column-gap: get-spacing-level(9);
        grid-row-gap: get-spacing-level(12);
        padding: get-spacing-level(12) 0;
      }
    }
  }
</style>
