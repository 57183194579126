<template>
  <div class="category-container">
    <Spinner
      :active="$fetchState.pending"
      :with-overlay="false" />
    <WrappedHubPage
      v-if="!$fetchState.pending"
      :header="header"
      :paginated-articles="paginatedArticles"
      :email-capture-data="emailCaptureData"
      :articles="articles"
      :show-fetch-button="canFetchMore"
      :category-ad="categoryAd"
      @fetch-next="fetchMoreArticles" />
  </div>
</template>

<script>
import chunk from 'lodash/chunk';
import {
  useRoute, useFetch, ref, useContext, reactive, defineComponent,
} from '@nuxtjs/composition-api';
import { Spinner } from '@fc/angie-ui';
import { useFetchCategory } from '~/hooks/useFetchCategory';
import { useFetchArticles } from '~/hooks/useFetchArticles';
import { useFetchPaginatedArticles } from '~/hooks/useFetchPaginatedArticles';
import { useFetchBlogPage } from '~/hooks/useFetchBlogPage';
import { WithSelector } from '~/components/wrappers/WithSelector';
import HubPage from '~/components/HubPage.vue';
import { selectHubPage } from '~/selectors/selectHubPage';
import { renderBlockValue } from '~/renderers/renderBlockValue';
import routeJson from '~/nuxtPageRoutes.json';
import { objectHasKey } from '~/utils/helpers';

export default defineComponent({
  name: 'CategoryHubPage',
  components: {
    Spinner,
    WrappedHubPage: WithSelector(HubPage, selectHubPage),
  },
  async validate({ params }) {
    return objectHasKey(routeJson, `/c/${params.category}`);
  },
  setup() {
    const route = useRoute();
    const { error } = useContext();
    const slug = route.value.params.category;
    const { rawCategoryData, fetchCategory } = useFetchCategory();
    const featuredArticles = useFetchArticles();
    const { page, fetchPage } = useFetchBlogPage('category-hub');
    const categoryAd = ref(null);
    const {
      getPaginatedArticles,
      canFetchMore,
      newArticles,
    } = useFetchPaginatedArticles();

    const initialArticles = ref([]);
    const paginatedArticles = ref([]);
    const emailCaptureData = reactive({
      emailCaptureTitle: '',
      emailCaptureDescription: '',
      emailCaptureButtonCta: '',
    });
    const paginationOptions = ref({
      categoryUuid: '',
      coverImg: true,
      page: 1,
      perPage: 18,
    });

    async function setPaginationRequirements() {
      await fetchCategory(slug);
      paginationOptions.value.categoryUuid = rawCategoryData.value.uuid;
    }

    async function fetchMoreArticles() {
      if (!paginationOptions.value.categoryUuid.length) {
        await setPaginationRequirements();
      }

      paginationOptions.value.page += 1;
      await getPaginatedArticles(paginationOptions.value);
      chunk(newArticles.value, 6).forEach((_chunk) => paginatedArticles.value.push(_chunk));
    }

    useFetch(async () => {
      try {
        await setPaginationRequirements();
        await featuredArticles.fetchArticles({
          categoryUuid: rawCategoryData.value.uuid, tags: 'featured', coverImg: true,
        });
        await getPaginatedArticles(paginationOptions.value);
        await fetchPage();

        initialArticles.value = newArticles.value;
        categoryAd.value = renderBlockValue(page, 'hub.category.ad.small_1');
        emailCaptureData.emailCaptureTitle = renderBlockValue(page, 'hub.category.email.title');
        emailCaptureData.emailCaptureDescription = renderBlockValue(page, 'hub.category.email.description');
        emailCaptureData.emailCaptureButtonCta = renderBlockValue(page, 'hub.category.email.button');
      } catch (err) {
        error({ statusCode: 404, message: 'Category page not found' });
      }
    });

    return {
      header: {
        rawCategoryData,
        rawAllArticles: initialArticles,
      },
      emailCaptureData,
      articles: featuredArticles.articles,
      categoryAd,
      fetchMoreArticles,
      canFetchMore,
      paginatedArticles,
    };
  }
});
</script>

<style lang="scss" scoped>
.category-container {
  flex: 1;
}
</style>
