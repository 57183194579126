<template>
  <div class="mobile-drop-down">
    <p
      class="mobile-drop-down__header fc-flex fc-justify-between fc-align-center"
      :class="[{'mobile-drop-down__header--open' :isActive}, {'mobile-drop-down__header--last': isLastCategory }]">
      <button
        :id="headingLabel"
        class="mobile-drop-down__button"
        :aria-expanded="isActive"
        :aria-controls="headingLabel"
        @click="toggle">
        <span class="fc-flex fc-justify-between fc-align-center">
          <span class="mobile-drop-down__title fc-bold fc-text-blue" :class="{'mobile-drop-down__title--open' :isActive}">
            {{ heading }}
          </span>
          <span class="mobile-drop-down__close-icon" :class="{'mobile-drop-down__close-icon--open':isActive}">
            <font-awesome-icon :icon="['fc', 'plus']" size="lg" />
          </span>
        </span>
      </button>
    </p>
    <div
      :id="`${heading} nav`"
      ref="expandedEl"
      class="mobile-drop-down__expanded"
      role="region"
      :aria-labelledby="headingLabel">
      <ul class="mobile-drop-down__topic">
        <li
          v-for="linkData in categoryTopicsList"
          :key="linkData.uuid"
          class="mobile-drop-down__topic-title">
          <NuxtLink
            :to="linkData.route"
            class="fc-text-blue"
            @click.native="handleClose">
            {{ linkData.name }}
          </NuxtLink>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { slugify } from '@fc/angie-ui';
import { ref, computed } from '@nuxtjs/composition-api';

export default {
  name: 'CollapsibleBox',
  props: {
    heading: {
      type: String,
      required: true,
    },
    categoryObject: {
      type: Object,
      required: true,
    },
    topics: {
      type: Array,
      required: true,
    },
    isLastCategory: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const categoryTopicsList = [props.categoryObject, ...props.topics];
    const isActive = ref(false);
    const expandedEl = ref(null);
    const headingLabel = computed(() => slugify(`nav ${props.heading} topics`));
    function toggle() {
      isActive.value = !isActive.value;
      if (expandedEl.value.style.maxHeight) {
        expandedEl.value.style.maxHeight = null;
      } else {
        expandedEl.value.style.maxHeight = `${expandedEl.value.scrollHeight * 2}px`;
      }
    }
    function handleClose() {
      toggle();
      emit('closeDrawer');
    }
    return {
      isActive,
      toggle,
      expandedEl,
      headingLabel,
      handleClose,
      categoryTopicsList,
    };
  }
};
</script>

<style lang="scss" scoped>
  .mobile-drop-down {
    &__button {
      background: transparent;
      border: none;
      width: 100%;
      padding: 0;
    }

    &__header {
      background: $spiralyze-light-gray;
      opacity: 0.96;
      width: 100%;
      margin: get-spacing-level(0);
      padding: get-spacing-level(7) get-spacing-level(8);
      transition: color 0.6s ease;
      height: 50px;

      &--last {
        border-left: 1px solid $color-egg-shell;
        border-right: 1px solid $color-egg-shell;
        border-bottom-left-radius: $container-radius;
        border-bottom-right-radius: $container-radius;
      }

      &--open {
        background: $color-blog-gray;
        opacity: 1;

        &.last-topic {
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        }
      }
    }

    &__topic-title {
      font-size: get-font-size-level(6);
      margin-top: get-spacing-level(0);
      margin-bottom: get-spacing-level(4);
      list-style-type: none;

      &--last {
        border: 1px solid $color-egg-shell;
        border-bottom-left-radius: $container-radius;
        border-bottom-right-radius: $container-radius;

        &:hover {
          border: 1px solid $color-blog-gray;
        }
      }
    }

    &__close {
      transform: rotate(45deg);
    }

    &__title {
      font-size: get-font-size-level(6);
      transition: color 0.6s ease;

      &--open {
        color: $color-white;
      }
    }

    &__expanded {
      background-color: $color-white;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.6s ease;
    }

    &__topic {
      margin: 0;
      padding: get-spacing-level(3) get-spacing-level(8) get-spacing-level(0);
    }

    &__close-icon {
      transition: 0.4s ease-out;

      svg path {
        fill: $color-blue;
      }

      &--open {
        transform: rotate(45deg);

        svg path {
          fill: $color-white;
        }
      }
    }
  }
</style>
