<template>
  <div class="footer__container fcb-container-sm">
    <div class="footer__categories fc-flex fc-column md:fc-row">
      <div
        v-for="(cat, index) in categories"
        :key="cat.uuid"
        class="footer__category">
        <div class="fc-hidden md:fc-block">
          <NuxtLink
            class="footer__category-title fc-bold fc-uppercase"
            :to="cat.route">
            {{ cat.name }}
          </NuxtLink>
          <ul class="footer__topic">
            <li
              v-for="topic in cat.topics"
              :key="topic.uuid"
              class="footer__topic-title">
              <NuxtLink :to="topic.route">
                {{ topic.name }}
              </NuxtLink>
            </li>
          </ul>
        </div>
        <!--googleoff: all-->
        <div class="fc-block md:fc-hidden">
          <CollapsibleBox
            :heading-label="slugify(cat.name + ' topic')"
            :heading="cat.name"
            :has-bottom-border="isLastCategory(index)"
            v-track-click="$options.name + '_' +cat.name">
            <template #default="slotProps">
              <ul class="footer__topic">
                <li
                  v-for="topic in (getCategoryTopicList(cat))"
                  :key="topic.uuid"
                  class="footer__topic-title">
                  <NuxtLink :to="topic.route" @click.native="slotProps.toggle">
                    {{ topic.name }}
                  </NuxtLink>
                </li>
              </ul>
            </template>
          </CollapsibleBox>
        </div>
        <!--googleon: all> -->
      </div>
      <div class="footer__category footer__contact">
        <p class="footer__category-title--contact fc-bold fc-uppercase">
          Contact
        </p>
        <ul class="footer__topic">
          <li class="footer__topic-title footer__topic-title--contact">
            <a href="mailto:info@joinfightcamp.com">
              <font-awesome-icon class="footer__support-links-icon" :icon="['fas', 'envelope']" />
              info@joinfightcamp.com
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { slugify } from '@fc/angie-ui';
import { Category } from '~/models/Category';
import CollapsibleBox from '~/components/CollapsibleBox.vue';

export default {
  components: { CollapsibleBox },
  name: 'Footer',
  props: {
    categories: {
      type: Array,
      required: true,
      validator(categories) {
        return categories.every((cat) => cat instanceof Category);
      }
    }
  },
  setup(props) {
    function getCategoryTopicList(categoryObject) {
      return [categoryObject, ...categoryObject.topics];
    }
    function isLastCategory(index) {
      return props.categories.length - 1 === index;
    }
    return {
      slugify,
      getCategoryTopicList,
      isLastCategory,
    };
  }
};
</script>

<style lang="scss">
.footer {
  &__container {
    padding: 0;
    width: 100%;
    max-width: $page-width;

    @media #{$tablet-up} {
      padding: 0 get-spacing-level(2);
    }
  }

  &__categories {
    width: 100%;
    border: none;
    padding: get-spacing-level(0) get-spacing-level(2);

    @media #{$tablet-up} {
      border-top: 1px $color-gray-border solid;
      padding: get-spacing-level(12) get-spacing-level(0) get-spacing-level(9);
    }
  }

  &__category {
    width: 100%;
  }

  &__category-title {
    display: block;
    font-size: get-font-size-level(4);
    margin: 0 0 get-spacing-level(4);
    color: $color-black;
    font-family: $font-stack-bold;

    &--contact {
      display: block;
      margin: get-spacing-level(6) get-spacing-level(0);
      color: $color-black;
      font-family: $font-stack-bold;
      font-size: get-font-size-level(7);

      @media #{$tablet-up} {
        font-size: get-font-size-level(4);
        margin: get-spacing-level(0) get-spacing-level(0) get-spacing-level(4);
      }
    }
  }

  &__topic {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__topic-title {
    text-transform: initial;
    margin: get-spacing-level(2) get-spacing-level(0);
    list-style-type: none;
    font-family: $font-stack-bold;
    font-size: get-font-size-level(6);

    @media #{$tablet-up} {
      font-size: 0.6875rem;
      margin: get-spacing-level(1) get-spacing-level(0);
    }

    a {
      @media #{$tablet-up} {
        font-family: $font-stack-bold;
        color: $color-black;
      }
    }

    &--contact {
      font-weight: bold;

      @media #{$tablet-up} {
        font-weight: normal;
      }
    }
  }

  &__contact {
    padding: get-spacing-level(0) get-spacing-level(4);

    a {
      color: $color-black;
    }

    @media #{$tablet-up} {
      padding: 0;
      min-width: 155px;
    }
  }

  &__support-links-icon {
    margin-right: get-spacing-level(2);
  }
}
</style>
