<template>
  <header class="article-header">
    <h1 class="article-header__heading fc-title">
      {{ article.title }}
    </h1>
    <div class="article-header__cover fc-flex fc-justify-center">
      <StoryblokImg
        v-if="article.coverImg.src"
        :image="article.coverImg"
        :width="1200"
        :height="495"
        sizes="xs:100vw, md:100vw, lg:1800px" />
    </div>
    <div class="article-header__sub-heading">
      <p class="article-header__description">
        {{ article.description }}
      </p>
      <div class="article-header__info fc-flex fc-justify-around fc-column fc-align-center md:fc-row">
        <p class="fc-bold fc-uppercase">
          Published: {{ publishedDate }}
        </p>
        <p class="fc-bold fc-uppercase">
          Topics: {{ article.topic.name }}, {{ article.category.name }}
        </p>
        <p class="fc-bold fc-uppercase">
          Author:
          <NuxtLink
            class="fc-text-red article-header__author"
            :to="profileUrl"
            v-scroll-to="'#authorSection'">
            {{ article.author.name }}
          </NuxtLink>
        </p>
      </div>
      <ShareIcons
        class="lg:fc-hidden"
        :url="article.fullUrl"
        :title="article.metaInfo.title" />
    </div>
  </header>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';
import { Article } from '~/models/Article';
import StoryblokImg from '~/components/StoryblokImg.vue';
import ShareIcons from '~/components/ShareIcons';

export default {
  name: 'ArticleHeader',
  components: {
    StoryblokImg,
    ShareIcons
  },
  props: {
    authorHasHubpage: {
      type: Boolean,
      required: true,
      default: false,
    },
    article: { type: Article, required: true }
  },
  setup(props) {
    const profileUrl = computed(() => (props.authorHasHubpage ? `/author/${props.article.author.slug}` : '#'));
    const publishedDate = computed(() => {
      const blogUnix = new Date(props.article.schedule).getTime();

      if (!blogUnix || Date.now() < blogUnix || !props.article.publishedAt) {
        return 'draft';
      }

      const dt = new Date(props.article.schedule);
      const month = dt.toLocaleString('default', { month: 'long' });
      return `${month} ${dt.getDate()}, ${dt.getFullYear()}`;
    });

    return {
      publishedDate,
      profileUrl,
    };
  }
};
</script>

<style lang="scss" scoped>
  .article-header {
    &__heading {
      text-align: center;
      padding: get-spacing-level(9) get-spacing-level(0);
      margin: 0 auto;
      width: 100%;
      max-width: 980px;

      @media #{$tablet-up} {
        padding: get-spacing-level(10) get-spacing-level(0);
        padding-bottom: get-spacing-level(8);
      }
    }

    &__sub-heading {
      border-bottom: 1px solid $color-gray-border;
      padding: get-spacing-level(8) get-spacing-level(0);
      margin: get-spacing-level(0) get-spacing-level(4);
      max-width: 980px;

      @media #{$tablet-up} {
        border-top: 1px solid $color-gray-border;
        margin: get-spacing-level(8) auto get-spacing-level(7);
        padding-bottom: get-spacing-level(7);
      }
    }

    &__cover {
      aspect-ratio: 1200 / 495;
      max-width: $page-width;
      max-height: 495px;
      margin: 0 auto;
    }

    &__description {
      text-align: center;
      width: 100%;
      padding: 0;
      margin: get-spacing-level(0) get-spacing-level(0) get-spacing-level(8);

      @media #{$tablet-up} {
        margin: get-spacing-level(0);
        font-size: get-font-size-level(8);
      }
    }

    &__info {
      margin: get-spacing-level(4) get-spacing-level(0) get-spacing-level(4);
      text-align: center;

      @media #{$desktop} {
        margin: get-spacing-level(4) get-spacing-level(0) get-spacing-level(0);
      }

      p {
        margin: 0;
      }
    }

    &__author {
      font-family: inherit;
    }
  }
</style>
