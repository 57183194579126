














import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'TertiaryButton',
  props: {
    scrollTo: {
      type: String,
      default: '',
      required: false,
    },
    to: {
      type: String,
      default: '',
      required: false,
    },
  },
  setup(props, context) {
    return {
      isNuxtLink: props.to ? 'NuxtLink' : 'button',
      onClick() {
        context.emit('click');
      }
    };
  }
});
