<template>
  <div class="fc-flex fc-column">
    <Spinner :active="$fetchState.pending" :with-overlay="false" />
    <WrappedArticlePage
      v-if="!$fetchState.pending"
      :author-has-hubpage="canFetchMore"
      :article="article"
      :related-articles="relatedArticles" />
  </div>
</template>

<script>
import {
  defineComponent,
  useRoute,
  useFetch,
  useContext,
  onErrorCaptured
} from '@nuxtjs/composition-api';
import { Spinner } from '@fc/angie-ui';
import { useFetchArticle } from '~/hooks/useFetchArticle';
import { useFetchPaginatedArticles } from '~/hooks/useFetchPaginatedArticles';
import { useFetchRelatedArticles } from '~/hooks/useFetchRelatedArticles';
import { WithSelector } from '~/components/wrappers/WithSelector';
import { selectArticle } from '~/selectors/selectArticle';
import { Article } from '~/models/Article';
import { useArticlePageMeta } from '~/hooks/useArticlePageMeta';
import ArticlePage from '~/components/article/ArticlePage';

export default defineComponent({
  name: 'SlugPage',
  components: {
    Spinner,
    WrappedArticlePage: WithSelector(ArticlePage, selectArticle, {
      propTypesOverride: {
        article: Object,
        relatedArticles: Array,
      }
    }),
  },
  head: {},
  setup() {
    const route = useRoute();
    const { error } = useContext();
    const { article, fetchArticle } = useFetchArticle({ articleSlug: route.value.params.slug, categorySlug: route.value.params.category });
    const { relatedArticles, fetchRelatedArticles } = useFetchRelatedArticles();
    const {
      getPaginatedArticles,
      canFetchMore,
    } = useFetchPaginatedArticles();

    useFetch(async () => {
      try {
        await fetchArticle();
        const _article = new Article(article.value);

        await fetchRelatedArticles({
          categoryUuid: (_article.category.uuid),
          articleUuid: (_article.uuid),
        });

        await getPaginatedArticles({
          coverImg: true,
          page: 1,
          perPage: 6,
          authorUuid: _article.author.uuid
        });
      } catch (err) {
        error({ statusCode: err.status, message: err.message });
      }
    });

    onErrorCaptured((err) => {
      error(err);
      return false;
    });

    useArticlePageMeta(article);

    return {
      article,
      relatedArticles,
      canFetchMore,
    };
  },
});
</script>
