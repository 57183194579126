<template>
  <div class="article-card__container">
    <NuxtLink
      class="article-card__link"
      :to="article.fullSlug">
      <div class="article-card__image fc-flex">
        <StoryblokImg
          v-if="article.coverImg.src"
          :image="article.coverImg"
          :lazy="true"
          sizes="xs:100vw, sm:700px, md:500px"
          :width="480"
          :height="200" />
      </div>
      <p
        class="article-card__title fc-text-black fc-bold"
        :class="titleClass">
        {{ article.title }}
      </p>
      <span
        class="fc-uppercase fc-bold"
        :class="linkColorClass">Read More +</span>
    </NuxtLink>
  </div>
</template>

<script>
import { computed, defineComponent } from '@nuxtjs/composition-api';
import StoryblokImg from '~/components/StoryblokImg.vue';
import { objectHasKey } from '~/utils/helpers';
import { Article } from '~/models/Article';

// TODO: move these dictionaries into global scope
export const validSizes = {
  small: 'small',
  normal: 'normal',
  big: 'big',
};
export const validStyles = {
  bold: 'bold',
  normal: 'normal',
  italic: 'italic',
};
export const validColors = {
  red: 'red',
  'red-shade': 'red-shade',
};
export const weights = {
  [validStyles.normal]: '',
  [validStyles.bold]: 'fc-bold',
  [validStyles.italic]: 'fc-italic'
};

export default defineComponent({
  name: 'ArticleCard',
  components: { StoryblokImg },
  props: {
    article: {
      type: Article,
      required: true,
    },
    titleSize: {
      type: String,
      default: validSizes.normal,
      validator(titleSize) {
        return objectHasKey(validSizes, titleSize);
      }
    },
    titleStyle: {
      type: String,
      default: validStyles.normal,
      validator(titleStyle) {
        return objectHasKey(validStyles, titleStyle);
      }
    },
    readMoreColor: {
      type: String,
      default: validColors.red,
      validator(readMoreColor) {
        return objectHasKey(validColors, readMoreColor);
      }
    }
  },
  setup(props) {
    return {
      titleClass: computed(() => [weights[props.titleStyle], props.titleSize].join(' ')),
      linkColorClass: computed(() => `fc-text-${props.readMoreColor}`),
    };
  }
});
</script>

<style lang="scss" scoped>
  .article-card {
    &__container {
      width: 100%;
    }

    &__image {
      aspect-ratio: 12 / 5;
    }

    &__title {
      font-size: get-font-size-level(6);
      margin: get-spacing-level(3) get-spacing-level(0) get-spacing-level(2) get-spacing-level(0);
      line-height: 1;

      @media #{$tablet-up} {
        margin: get-spacing-level(2) get-spacing-level(0) get-spacing-level(2) get-spacing-level(0);
      }

      &.small {
        font-size: get-font-size-level(6);
        line-height: 1.5;
      }

      &.big {
        font-size: 1.2rem;
        line-height: 1.2;

        @media #{$desktop} {
          font-size: get-font-size-level(9);
        }
      }
    }
  }
</style>
