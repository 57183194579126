












import { defineComponent, PropType } from '@nuxtjs/composition-api';
import { IGeneratedSitemapItem } from './SitemapItem.vue';

export interface ISitemapCategory {
  name: string,
  route: string
  articles: IGeneratedSitemapItem[]
}

export default defineComponent<{
  category: ISitemapCategory
}>({
  name: 'SitemapCategory',
  props: {
    category: {
      type: Object as PropType<ISitemapCategory>,
      required: true,
    }
  },
});
