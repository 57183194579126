<template>
  <div>
    <header class="blog-nav__header">
      <nav
        role="navigation"
        aria-label="main-navigation-menu"
        class="blog-nav__nav fc-flex fc-justify-between fc-bg-blue fc-text-white">
        <ul class="blog-nav__list">
          <li>
            <NuxtLink class="blog-nav__fc-logo" to="/">
              <span class="fc-screen-reader-only">Home</span>
              <font-awesome-icon :icon="['fc', 'logo']" class="blog-nav__fc-logo-icon" />
            </NuxtLink>
          </li>
          <li class="blog-nav__button">
            <a
              href="https://joinfightcamp.com/?utm_source=organicBlog&utm_medium=explore&utm_content=homepage"
              target="_blank"
              rel="noopener"
              class="button-layout button-primary button-small"
              v-track-click="'Explore_FightCamp_Button_Error_Nav'">
              Explore FightCamp
            </a>
          </li>
        </ul>
      </nav>
    </header>
    <Nuxt />
  </div>
</template>

<script>

export default {
  name: 'ErrorNav',
  mounted() {
    this.$analytics.pageViewed({ name: 'Error Page' });
  }
};
</script>

<style lang="scss" scoped>
  .blog-nav {
    &__header {
      position: sticky;
      top: 0;
      width: 100%;
      z-index: 2;
    }

    &__nav {
      position: relative;
      position: -webkit-sticky;
      top: 0;
      width: 100%;
      padding: 0 get-spacing-level(5);
      z-index: 2;

      @media #{$desktop} {
        position: relative;
      }
    }

    li {
      padding: 0;
      margin: 0;
    }

    &__list {
      display: flex;
      list-style-type: none;
      margin: 0;
      padding: 0;
      height: 58px;
      width: 100%;
    }

    &__fc-logo {
      color: $color-white;
      padding: get-spacing-level(3) get-spacing-level(3) get-spacing-level(3) 0;
    }

    &__fc-logo-icon {
      width: 40px;
      height: 100%;
      align-self: center;
    }

    &__button {
      margin-left: auto;
      align-self: center;
    }
  }

</style>
