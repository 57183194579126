<template>
  <div class="storyblok-img--container fc-flex">
    <NuxtPicture
      provider="storyblok"
      class="storyblok-img fc-flex"
      :class="shapeClass"
      :modifiers="{ filters: { focal: imageObj.focus } }"
      :width="width"
      :height="height"
      :src="imageObj.src"
      :sizes="sizes"
      :alt="imageObj.alt"
      :format="format"
      :loading="lazy ? 'lazy' : ''" />
  </div>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';
import { Image } from '~/models/Image';

const shapeClasses = {
  circle: 'storyblok-img--circle',
  roundedCorners: 'storyblok-img--round-corners',
  sharpCorners: 'storyblok-img--sharp-corners'
};

export default {
  name: 'StoryblokImg',
  props: {
    image: { type: Object, required: true },
    sizes: { type: String, required: true },
    lazy: { type: Boolean, default: false },
    width: { type: Number, default: null },
    height: { type: Number, default: null },
    format: { type: String, default: 'webp' },
    shape: {
      type: String,
      default: 'sharpCorners',
      validator(shape) {
        const validShape = ['circle', 'sharpCorners', 'roundedCorners'];
        return validShape.includes(shape);
      }
    }
  },
  setup(props) {
    return {
      shapeClass: computed(() => shapeClasses[props.shape]),
      imageObj: computed(() => (props.image instanceof Image ? props.image : new Image(props.image))),
    };
  }
};
</script>

<style lang="scss">
  .storyblok-img {
    @supports (not(aspect-ratio: 1)) {
      &--container {
        justify-content: center;
        align-items: center;
        overflow: hidden;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    @supports (aspect-ratio: 1) {
      width: 100%;
      max-width: 100%;

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
        height: min-content;
      }

      &--container {
        flex: 1;
      }

      &--sharp-corners {
        img {
          border-radius: 0;
        }
      }

      &--circle {
        img {
          border-radius: 100%;
        }
      }

      &--round-corners {
        img {
          border-radius: $container-radius;
        }
      }
    }
  }
</style>
