<template>
  <div class="bottom-footer">
    <div class="bottom-footer__wrapper fcb-container-sm fc-flex fc-column">
      <div class="bottom-footer__legal fc-flex fc-justify-center">
        <div class="bottom-footer__legal-content fc-flex-col-center">
          <p class="bottom-footer__copyright">
            {{ copyright }}
          </p>
          <div class="fc-flex-row-center">
            <a
              class="bottom-footer__legal-link"
              href="https://joinfightcamp.com/legal-terms/">
              Legal Terms
            </a>
            <a
              class="bottom-footer__legal-link"
              href="https://joinfightcamp.com/privacy-policy/">
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
      <div class="bottom-footer__social fc-flex fc-justify-center">
        <SocialIcons
          class="bottom-footer__icons fc-flex fc-justify-center"
          :icons="iconList" />
      </div>
    </div>
  </div>
</template>

<script>
import { SocialIcons } from '@fc/angie-ui';
import { SITE_COPYRIGHT } from '~/utils/constants';

export default {
  name: 'BottomFooter',
  components: {
    SocialIcons,
  },
  setup() {
    return {
      copyright: SITE_COPYRIGHT,
      iconList: [
        { name: 'twitter', url: 'https://twitter.com/joinfightcamp/' },
        { name: 'facebook', url: 'https://www.facebook.com/fightcamp/' },
        { name: 'instagram', url: 'https://www.instagram.com/fightcamp' },
        { name: 'youtube', url: 'https://www.youtube.com/channel/UCJ6J1r5u8CFsbyVZ03A6IaQ' },
        { name: 'pinterest', url: 'https://www.pinterest.com/JoinFightCamp' },
        { name: 'tiktok', url: 'https://www.tiktok.com/@fightcamp' },
        { name: 'rss', url: '/feed.xml', type: 'application/rss+xml' }
      ]
    };
  },
};
</script>

<style scoped lang="scss">
.bottom-footer {
  background-color: $color-light-gray-1;

  &__wrapper {
    padding: get-spacing-level(9) get-spacing-level(0);
  }

  &__copyright {
    font-size: 0.7rem;
    margin: get-spacing-level(4) 0 0;

    @media #{$tablet-up} {
      font-size: get-font-size-level(3);
    }
  }

  &__legal-link {
    text-decoration: underline;
    font-size: 0.7rem;
    color: $color-black;
    margin: get-spacing-level(3) get-spacing-level(4) get-spacing-level(0);

    @media #{$tablet-up} {
      font-size: get-font-size-level(3);
    }
  }

  &__social {
    order: -1;
    padding: 0 get-spacing-level(4);
  }

  &__icons {
    grid-auto-flow: column;
    gap: get-spacing-level(4);
    flex-wrap: wrap;
  }
}
</style>
