<template>
  <Carousel
    class="md:fc-hidden"
    screen-label="category carousel"
    infinite-slides-enabled
    :timer-limit="7000"
    three-slide-view
    mode="timer_on_load"
    hide-navigation
    :slide-width-percentage="0.65">
    <Slide
      v-for="category in categories"
      :key="category.uuid"
      :screen-label="category.blockImage.alt">
      <div class="slide__container">
        <CategoryButton :category="category" />
      </div>
    </Slide>
  </Carousel>
</template>

<script>
import { Carousel, Slide } from '@fc/angie-ui';
import CategoryButton from './CategoryButton.vue';

export default {
  name: 'CategoriesMobile',
  components: {
    CategoryButton,
    Carousel,
    Slide,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    }
  },
};
</script>

<style lang="scss" scoped>
  .slide {
    &__container {
      margin: get-spacing-level(8) get-spacing-level(5) get-spacing-level(12);
      box-shadow: 0 4px 8px 4px rgba(15, 30, 60, 0.1);
      border-radius: $input-radius;
    }
  }
</style>
