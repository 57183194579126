<template>
  <EmailCapture
    class="email-capture fc-text-eggshell"
    :cta-text="ctaText"
    :is-tablet-up-column-order="isTabletUpColumnOrder"
    :errors="errors"
    :is-submitted="isSubmitted"
    @submitEmail="submitEmail">
    <h2 class="blog-email-capture__title fc-heading-2">
      {{ title }}
    </h2>
    <p class="blog-email-capture__text fc-text-white">
      {{ description }}
    </p>
  </EmailCapture>
</template>

<script>
import { ref, useContext } from '@nuxtjs/composition-api';
import { EmailCapture } from '@fc/angie-ui';
import { useAnalytics } from '@fc/app-common';

export default {
  name: 'BlogEmailCapture',
  components: {
    EmailCapture,
  },
  props: {
    ctaText: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    isTabletUpColumnOrder: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { $netlify } = useContext();
    const errors = ref([]);
    const isSubmitted = ref(false);
    const analytics = useAnalytics();

    const submitEmail = async (email) => {
      try {
        await $netlify.post('drip', { email, event: 'Blog | email signup' });
        isSubmitted.value = true;
        errors.value = [];
        analytics.campaignSubscribed({
          name: 'Blog',
          type: 'Static Form',
          email,
        });
      } catch (err) {
        errors.value = ['Sorry, there was an error signing you up. Please try again.'];
      }
    };

    return {
      errors,
      isSubmitted,
      submitEmail,
    };
  },
};
</script>

<style lang="scss" scoped>
  .blog-email-capture {
    &__title {
      font-size: get-font-size-level(12);
      margin-bottom: get-spacing-level(4);
    }

    &__text {
      margin-bottom: get-spacing-level(9);
      font-size: get-font-size-level(4);

      @media #{$tablet-up} {
        margin-bottom: 0;
      }
    }
  }
</style>
