<template>
  <div>
    <iframe
      width="100%"
      height="375"
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
      :src="videoUrl" />
  </div>
</template>

<script>
import { computed } from '@nuxtjs/composition-api';
import { getYoutubeEmbedUrl, getYoutubeId } from '~/utils/helpers';

export default {
  name: 'EmbedVideoYoutube',
  props: {
    body: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    return {
      videoUrl: computed(() => getYoutubeEmbedUrl(getYoutubeId(props?.body?.video_url))),
    };
  },
};
</script>

<style scoped lang="scss">
  iframe {
    display: flex;
    margin: get-spacing-level(7) 0;
  }
</style>
