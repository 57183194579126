<template>
  <div />
</template>

<script>
import routeJson from '~/nuxtPageRoutes.json';
import { objectHasKey } from '~/utils/helpers';

export default {
  name: 'index',
  async validate({ params }) {
    return objectHasKey(routeJson, `${params.category}`);
  },

};
</script>
